.customer-service-landing-block {
  display: block !important; /* All divs are hidden at first, must change that for landing page */
  float: $ldirection;
  width: 50%;
  height: 320px;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 2em 50px);
  text-align: center;
  position: relative;
  /* border-bottom: 1px solid $color-gray-light-alt; */
  @include breakpoint($small-down) {
    float: none;
    width: 100%;
    height: auto;
  }
  @include breakpoint(($medium-range) (orientation portrait)) {
    height: 365px;
  }
  &__image {
    width: 50px;
    height: 46px;
  }
  &__content {
    margin-top: 0;
  }
  span {
    font-size: 3em;
  }
  h3 {
    background: none !important;
    border: none !important;
  }
  a {
    padding-bottom: 0.5em;
    font-family: $body-font-bold;
    text-decoration: none;
    text-transform: uppercase;
    /* border-bottom: 2px solid $color-pink; */
    position: absolute;
    display: block;
    width: 100%;
    bottom: 52px;
    #{$ldirection}: 0;
    text-align: center;
  }
  a:hover {
    text-decoration: none;
    border: none;
  }
}

#landing-chat,
#landing-text,
#landing-call {
  border-top: 1px solid $color-gray-alt;
  @include breakpoint($medium-up) {
    border-top: 0;
  }
}

#landing-email {
  background: url('https://m.bobbibrowncosmetics.ca/media/images/account/bg_account_landing.gif') no-repeat 100% 0;
}

#landing-email,
#landing-faqs {
  border-top: 1px solid $color-gray-alt;
}
