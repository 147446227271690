$iphone-portrait-only: max-width 375px !default; // iphone 6 portrait
// mobile specific adjustments
$screen: 'only screen' !default;
$landscape: '#{$screen} and (orientation: landscape)' !default;
$portrait: '#{$screen} and (orientation: portrait)' !default;
// large android tablets
$device-tablet-android: '#{$screen} and (min-width: 800px) and (max-width: 1280px)' !default; // large android tablets

// Landing page
.slww {
  padding: 0;
  max-width: 1024px;
  margin: 0 auto;
  h1 {
  }
  h2 {
    font-family: $brandon-text-bold;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  h4 {
    font-family: 'Lato Black';
    font-size: 30px;
    line-height: 1em;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 52px;
    }
  }
  .wrap {
    padding: 0;
    position: relative;
  }
  .quickshop_container {
    text-align: center;
    margin: 0 0 50px 0;
    @include breakpoint($medium-up) {
      margin: 50px 0;
    }
    .basic-responsive {
      overflow: hidden;
    }
    .quickshop {
      text-align: left;
    }
    .button--outline {
      color: $color-black;
      border: none;
      border-bottom: 2px solid $color-black;
      padding: 0.75em 0;
    }
  }
  .quickshop_container__subtitle {
    font-size: 12px;
    margin: 5px 0 12px;
    @include breakpoint($medium-up) {
      font-size: 20px;
      margin: 22px;
    }
  }
  .videos-plus {
    .quickshop-wrapper {
      position: auto;
    }
    .quickshop_container {
      margin: 0;
      cursor: pointer;
    }
  }
  .product_models {
    height: 300px;
    @media (min-width: 667px) {
      height: 472px;
    }
  }
  .product_stripes {
    height: 70px;
    @include breakpoint($medium-up) {
      height: 130px;
    }
  }
  .product_product {
    height: 295px;
    @media (min-width: 667px) {
      height: 500px;
    }
  }
  .product_benefits {
    line-height: 1.5em;
    text-align: left;
    padding: 0 2% 0 30%;
    overflow: hidden;
    @include breakpoint($medium-up) {
      line-height: 2em;
      text-align: center;
      width: 33%;
      float: left;
      padding: 39px;
    }
    .hed {
      font-family: $brandon-text-bold;
      font-size: 12px;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 20px;
        margin-top: 20px;
      }
    }
    .dek {
      font-size: 12px;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
    @media (max-width: 767px) {
      position: relative;
      margin: 20px 0;
      img:nth-child(1) {
        width: 31px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 9%;
      }
      img:nth-child(2) {
        position: absolute;
        left: 23%;
        top: 50%;
        transform: translateY(-50%);
        width: 2.5px;
      }
    }
  }
  @media (max-width: 767px) {
    .shade-slider .slick-slider {
      margin-bottom: 12px;
    }
  }
  .block_problem_skin_header {
    text-align: center;
    padding: 30px 0;
    @media (max-width: 767px) {
      background-image: url(https://m.bobbibrowncosmetics.ca/media/export/cms/collections/slww/problem_skin_video/MOBILE_VIDEO_BG.jpg);
      background-size: cover;
    }
    .hed {
      font-family: Lato Black;
      font-size: 22px;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 31px;
        margin-bottom: 18px;
      }
    }
    .dek {
      font-size: 12px;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
  }
  .video-carousel__block {
    background-color: $color-white;
    min-height: 640px;
    @media (max-width: 767px) {
      background-image: url(https://m.bobbibrowncosmetics.ca/media/export/cms/collections/slww/problem_skin_video/MOBILE_VIDEO_BG.jpg);
      background-size: cover;
      background-position: center bottom;
    }
    @include breakpoint($tablet-down) {
      min-height: 495px;
    }
    @media (max-width: 736px) {
      min-height: 572px;
    }
    @media (max-width: 414px) {
      min-height: 422px;
    }
    .device-mobile & {
      @include breakpoint($iphone-portrait-only) {
        min-height: 395px;
      }
    }
    @media #{$device-tablet-android} {
      @media #{$portrait} {
        min-height: 640px;
      }
      @media #{$landscape} {
        min-height: 655px;
      }
    }
    .video-block__media--title {
      color: $color-black;
    }
    .video-carousel__menu {
      margin: 0 1px;
      width: unset;
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    .video-carousel__menu .selectbox {
      color: $color-black;
      border: 1px solid $color-black;
      background-color: $color-white;
    }
    .video__image__thumb {
      padding: 0 6px;
    }
    .video-carousel__related_products__button {
      color: $color-black;
      text-transform: uppercase;
      bottom: 12px;
      background-color: $color-white;
      border-color: $color-black;
    }
  }
  @media (max-width: 767px) {
    .video-carousel__block__header {
      font-family: 'Lato Black';
      color: $color-black;
      text-transform: uppercase;
      padding: 20px;
      background-image: url(https://m.bobbibrowncosmetics.ca/media/export/cms/collections/slww/problem_skin_video/MOBILE_VIDEO_BG.jpg);
      background-size: cover;
    }
  }
  .homepage-slide.module .homepage-slide__slide.module {
    margin: 0;
  }
  .homepage-slide.before_after {
    top: -13px;
    .homepage-slide__inner {
      margin-bottom: 0;
      @include breakpoint($medium-up) {
        padding-bottom: 18px;
      }
    }
    @media (max-width: 767px) {
      .homepage-slide__dropdown {
        padding-top: 32px;
      }
    }
    .product-grid--carousel .slick-prev {
      left: unset;
    }
    .icon {
      font-size: 24px;
      position: absolute;
      right: 0;
      top: 3px;
      z-index: 1000;
      cursor: pointer;
    }
  }
  .block_container.olapic {
    @include breakpoint($medium-up) {
      border-top: 1px solid $color-gray;
    }
    h3 {
      font-family: 'Lato Black';
      font-size: 27px;
      text-transform: uppercase;
      @media (max-width: 767px) {
        padding: 30px 0 0;
      }
    }
    .olapic-block__copy {
      line-height: 1.5em;
    }
  }
  .evergreen_services {
    border-top: 1px solid $color-gray;
  }
}
