.shade-dot-filter {
  @mixin scrollbars($size, $border-radius, $foreground-color, $background-color) {
    ::-webkit-scrollbar {
      width: $size;
      height: $size;
      background: $background-color;
    }
    ::-webkit-scrollbar-thumb {
      background: $foreground-color;
      border-radius: border-radius;
    }
    ::-webkit-scrollbar-track {
      background: $background-color;
      width: 0;
    }
  }
  @include scrollbars(0.25em, 1px, rgba(84, 84, 84, 0.5), rgba(84, 84, 84, 0.1));
  h2.resp-accordion {
    display: none;
  }
  .color-families {
    top: 414px;
    position: relative;
    width: 100%;
    @include breakpoint($small-screen) {
      top: 375px;
    }
    .hide-tabs {
      display: none;
    }
    .color-families-nav {
      &.show {
        display: flex;
      }
      &.hide {
        display: none;
      }
    }
  }
  .swatch-gallery__photo--large {
    display: none;
  }
  ul.resp-tabs-list {
    text-align: center;
    max-width: 768px;
    width: 100%;
    display: flex;
    position: relative;
    bottom: 414px;
    overflow-x: scroll;
    li {
      font-weight: 100;
      text-transform: uppercase;
      width: 160px;
      padding: 18px 24px;
      border-bottom: 1px solid $color-light-gray;
      &.resp-tab-active {
        font-weight: 600;
        border-bottom: 1px solid $color-black;
        background-color: transparent;
      }
    }
  }
  .color-families-content {
    &.resp-tabs-container {
      background-color: transparent;
      clear: both;
    }
    .resp-tab-content {
      height: 414px;
      background-color: transparent;
      padding: 0;
      border: 0;
      @include breakpoint($small-screen) {
        height: 375px;
      }
    }
    .shade-picker__shades {
      overflow: auto;
      height: 100%;
    }
    .shade-picker__shade-item {
      width: 25%;
      height: 25%;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint($tablet) {
        width: 25%;
        height: 25%;
      }
    }
    .shade-picker__shade {
      width: 60%;
      height: 60%;
      box-shadow: $color-white 0 0 1px 1px;
      border: 1px solid transparent;
      &.selected {
        border: 3px solid $color-white;
        box-shadow: $color-black 0 0 1px 1px;
      }
    }
  }
  .swatch-gallery {
    width: 100%;
    position: relative;
    bottom: 414px;
    @include breakpoint($small-screen) {
      bottom: 375px;
    }
    .slick-wrapper {
      position: relative;
      @include breakpoint($tablet) {
        overflow: hidden;
      }
    }
    .swatch-gallery-product-info {
      text-transform: uppercase;
      position: absolute;
      top: 18px;
      #{$ldirection}: 18px;
      @include breakpoint($tablet) {
        position: static;
        float: $left;
        text-align: $left;
      }
      h3 {
        font-family: $brandon-text-bold;
      }
    }
    &__shade-name {
      font-family: $brandon-text-bold;
    }
    .cta {
      position: absolute;
      bottom: -420px;
      right: 0;
      left: 0;
      margin: 0 auto;
      text-align: center;
      @include breakpoint($tablet) {
        position: static;
        float: $right;
      }
    }
    .button--secondary {
      background-color: $color-black;
    }
    .slick-slider {
      margin: 0 auto 32px;
      .slick-next,
      .slick-prev {
        height: 60px;
      }
    }
    .product__inventory-status {
      position: absolute;
      right: 0;
      bottom: -20px;
    }
  }
  @include breakpoint($tablet) {
    @include scrollbars(0.5em, 3px, rgba(84, 84, 84, 0.5), rgba(84, 84, 84, 0.1));
    position: relative;
    margin: 120px auto 30px;
    max-width: 1280px;
    display: flex;
    .swatch-gallery__photo--large {
      z-index: 1;
      &.active {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .product-gallery__main {
      background: $color-white;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .color-families {
      width: 50%;
      position: unset;
    }
    ul.resp-tabs-list {
      position: absolute;
      max-width: 860px;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -100px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      li {
        font-size: 15px;
        width: 160px;
        padding: 18px 24px;
        border-bottom: 1px solid $color-light-gray;
        font-weight: 100;
      }
    }
    .color-families {
      width: 50%;
    }
    .color-families-content {
      height: 100%;
      &.resp-tabs-container {
        height: 100%;
      }
      .resp-tab-content {
        height: 384px;
        padding: 0;
      }
      .shade-picker__shades {
        display: block;
        height: 384px;
        -webkit-overflow-scrolling: touch;
      }
    }
    .swatch-gallery {
      width: 50%;
      position: static;
      .slick-wrapper {
        position: relative;
        height: 384px;
      }
      .swatch-gallery-product-info {
        position: absolute;
        left: 26px;
        top: 26px;
        z-index: 99;
      }
      .cta {
        position: absolute;
        #{$rdirection}: 0;
        bottom: 3%;
        z-index: 99;
      }
      .slick-slider {
        margin: 0;
        height: 384px;
      }
    }
    &.white-copy {
      .swatch-gallery-product-info {
        color: $color-white;
      }
    }
  }
  @include breakpoint($desktop) {
    max-width: 1024px;
    .color-families-content {
      .resp-tab-content {
        height: 512px;
      }
      .shade-picker__shades {
        height: 512px;
      }
      .slick-slider {
        height: 512px;
      }
    }
    .swatch-gallery {
      .slick-wrapper {
        height: 512px;
      }
      .slick-slider {
        margin: 0;
        height: 512px;
      }
    }
  }
  @include breakpoint($xxlarge-range) {
    max-width: 1280px;
    .color-families-content {
      .resp-tab-content {
        height: 640px;
      }
      .shade-picker__shades {
        height: 640px;
      }
      .slick-slider {
        height: 640px;
      }
    }
    .swatch-gallery {
      .slick-wrapper {
        height: 640px;
      }
      .slick-slider {
        margin: 0;
        height: 640px;
      }
    }
  }
  &.square-swatch {
    .color-families-content {
      .shade-picker__shades {
        padding: 0;
        -webkit-overflow-scrolling: touch;
      }
      .shade-picker__shade-item {
        padding: 0;
        margin: 0;
        .selected:after {
          content: url(https://m.bobbibrowncosmetics.ca/media/export/cms/global/check-white.png);
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      .shade-picker__shade {
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        &.selected {
          border: 0;
          box-shadow: none;
        }
      }
    }
    @include breakpoint($tablet) {
      .color-families-content {
        .shade-picker__shades {
          display: block;
        }
      }
    }
  }
}
