@mixin giftcard-tab-text {
  font-family: $body-font-bold;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

@mixin giftcard-container {
  max-width: $max-width;
  margin: 0 auto;
  input[type="text"],
  .egiftcard-message {
    width: 100%;
    @include breakpoint($medium-up) {
      background: $color-light-brown;
      border: 1px solid $color-light-brown;
      font-family: $body-font;
      font-size: 18px;
      &:focus {
        border-color: $color-beige-dark;
      }
    }
  }
}

@mixin giftcard-mobile-header {
  font-family: $body-font-bold;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  line-height: 40px;
}

.giftcard {
  &__tabs {
    @include clearfix;
    width: 480px;
    height: 38px;
    @include swap_direction(margin, 0 auto);
    border-#{$ldirection}: 1px solid $color-black;
    border-#{$rdirection}: 1px solid $color-black;
    &-container {
      //display: none;
      @include breakpoint($medium-up) {
        max-width: $max-width;
        @include swap_direction(margin, 8px auto 0 auto);
      }
    }
  }
  &__tab {
    @include giftcard-tab-text;
    float: $ldirection;
    height: 38px;
    line-height: 38px;
    background: $color-gray-background;
    text-align: center;
    &--purchase {
      width: 271px;
    }
    &--balance {
      width: 207px;
    }
    &-link {
      @include link-reset;
      display: block;
      line-height: 37px;
      background: $color-white;
      border-#{$ldirection}: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      .giftcard__tabs-container--balance & {
        border-#{$ldirection}: none;
        border-#{$rdirection}: 1px solid $color-black;
      }
    }
  }
  &__container {
    @include giftcard-container;
  }
  &__description {
    @include breakpoint($medium-up) {
      max-width: $max-width;
      min-height: 285px;
      @include swap_direction(margin, 0 auto);
      @include swap_direction(padding, 60px 530px 60px 60px);
      background: url(https://m.bobbibrowncosmetics.ca/media/images/giftcards/giftcard_bg.png) no-repeat top $rdirection;
    }
    &-container {
      max-width: $max-width;
      @include swap_direction(margin, 0 auto);
      background: $color-gray-background;
      @include breakpoint($medium-up) {
        border-top: 1px solid $color-black;
        margin-top: -1px;
      }
    }
    &-header {
      font-family: $body-font-bold;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      padding: 20px 0;
      margin-bottom: 0;
      text-align: center;
      @include breakpoint($medium-up) {
        font-size: 25px;
        line-height: 28px;
        letter-spacing: 0.24em;
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 0 0 16px 0);
        text-align: $ldirection;
      }
    }
    &-text {
      background: $color-white;
      text-align: center;
      font-size: 15px;
      line-height: 22px;
      @include swap_direction(padding, 11px 20px);
      @include breakpoint($medium-up) {
        background: $color-gray-background;
        text-align: $ldirection;
        font-size: 18px;
        line-height: 29px;
        @include swap_direction(padding, 0);
      }
    }
    &-sub-text {
      @include breakpoint($small-down) {
        display: block;
        font-family: $body-font-bold;
      }
    }
  }
  &__check-balance {
    //mobile only
    @include breakpoint($small-down) {
      @include link-reset;
      @include icon('arrow_$rdirection', after, true);
      @include giftcard-mobile-header;
      position: relative;
      display: block;
      background: $color-nude;
      @include swap_direction(padding, 0 20px);
      border-top: 1px solid $color-gray-alt;
      border-bottom: 1px solid $color-gray-alt;
      &:after {
        position: absolute;
        top: 0;
        #{$rdirection}: 20px;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
  &__purchase {
    //mobile only
    @include breakpoint($small-down) {
      @include giftcard-mobile-header;
      border-bottom: 1px solid $color-gray-alt;
      @include swap_direction(margin, 0 20px);
    }
  }
  &__columns {
    @include clearfix;
  }
  &__images {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      float: $ldirection;
      width: 50%;
    }
  }
  &__image {
    display: none;
    width: 100%;
    &--placeholder {
      width: 100%;
    }
  }
  &__step {
    display: inline-block;
    color: $color-pink-red;
    @include breakpoint($medium-up) {
      display: block;
      margin-top: 32px;
      font-size: 22px;
      line-height: 24px;
    }
    &-header {
      display: inline-block;
      @include breakpoint($medium-up) {
        display: block;
        @include swap_direction(padding, 24px 0 36px 0);
        font-size: 25px;
        line-height: 28px;
      }
    }
    &-container {
      font-family: $body-font-bold;
      font-weight: normal;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      margin-top: 12px;
      @include breakpoint($medium-up) {
        margin-top: 0;
      }
    }
  }
  &__selected {
    width: 100%;
    @include swap_direction(padding, 35px 14px 0 30px);
  }
  &__details {
    width: 100%;
    padding: 0 20px;
    @include breakpoint($medium-up) {
      float: $ldirection;
      width: 50%;
      @include swap_direction(padding, 0);
    }
  }
  &__selection {
    display: none;
    @include swap_direction(padding, 28px 20px 10px 20px);
    @include swap_direction(margin, 0);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 50px 20px);
    }
    .giftcard__theme {
      padding: 1px;
      .giftcard_slide_image {
        @include swap_direction(margin, 0 auto);
        border: 3px solid $color-white;
        border-radius: 8px;
        height: 75px;
        width: 125px;
        cursor: pointer;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0 2px);
        }
        &.selected {
          box-shadow: 0 0 0 1px $color-black;
        }
      }
    }
    .slick-prev,
    .slick-next {
      &:before {
        font-size: 30px;
      }
    }
    .slick-prev {
    }
    .slick-next {
      #{$ldirection}: 486px;
      @include swap_direction(padding, 2px 10px 2px 0);
      &:before {
        #{$rdirection}: auto;
      }
    }
  }
  &__value {
    margin: 0 10px;
    border-top: 1px solid $color-gray-alt;
    border-bottom: 1px solid $color-gray-alt;
    @include breakpoint($medium-up) {
      padding-bottom: 50px;
    }
  }
  &__sku-select {
    width: 100%;
    @include swap_direction(margin, 20px 0 14px 0);
    @include breakpoint($medium-up) {
      display: none !important;
      @include swap_direction(margin, 0);
    }
    @include breakpoint($small-down) {
      display: block;
    }
  }
  &__price {
    @include clearfix;
    margin-top: 34px;
    border-top: 1px solid $color-black;
    border-#{$rdirection}: 1px solid $color-black;
    &-option {
      float: $ldirection;
      width: 25%;
      padding: 0 4px;
      font-family: $body-font-bold;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: $color-black;
      text-align: center;
      border-#{$ldirection}: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      cursor: pointer;
      &.selected {
        background: $color-black;
        color: $color-white;
      }
    }
  }
  //inputs
  &__info {
    @include clearfix;
    @include swap_direction(margin, 0 10px);
    padding-bottom: 30px;
    border-bottom: 1px solid $color-gray-alt;
  }
  &__email {
    margin: 20px 0 14px 0;
    @include breakpoint($medium-up) {
      float: $ldirection;
      width: 48%;
      @include swap_direction(margin, 0 0 14px 0);
    }
  }
  &__confirm-email {
    margin-bottom: 14px;
    @include breakpoint($medium-up) {
      float: $ldirection;
      width: 48%;
      @include swap_direction(margin, 0 0 14px 4%);
    }
  }
  &__to {
    margin-bottom: 14px;
    @include breakpoint($medium-up) {
      clear: $ldirection;
      float: $ldirection;
      width: 48%;
    }
  }
  &__from {
    margin-bottom: 14px;
    @include breakpoint($medium-up) {
      float: $ldirection;
      width: 48%;
      @include swap_direction(margin, 0 0 14px 4%);
    }
  }
  &__message {
    clear: both;
    .egiftcard-message {
      min-height: 192px;
      @include swap_direction(padding, 10px);
    }
  }
  &__button {
    @include swap_direction(padding, 14px 10px);
    width: 100%;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 30px 0);
      width: auto;
    }
    input[type='submit'] {
      @include input-button--secondary;
      display: block;
      @include swap_direction(margin, 0 auto);
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
  }
  &__guides {
    @include breakpoint($medium-up) {
      display: block;
      margin-bottom: 20px;
    }
    &-section {
      background: $color-gray-background;
      border-top: 1px solid $color-white;
      border-bottom: 1px solid $color-white;
    }
    &-header {
      @include icon('play');
      height: 58px;
      padding-#{$ldirection}: 20px;
      font-family: $body-font-bold;
      font-weight: normal;
      font-size: 14px;
      line-height: 58px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: $color-black;
      background-color: $color-gray-background;
      cursor: pointer;
      &:before {
        font-size: 20px;
        @include swap_direction(margin, -3px 5px 0 0);
        .giftcard__guides--is_open & {
          @include transform(rotate(90deg));
          @include swap_direction(margin, 0 5px 0 0);
        }
      }
    }
    &-text {
      @include opacity(0);
      @include transition(opacity, 0.3s ease-out);
      overflow: hidden;
      @include swap_direction(padding, 0 40px 0 50px);
      height: 0;
      ul {
        margin-bottom: 30px;
      }
      p:last-child,
      ul:last-child {
        margin-bottom: 0;
      }
      .giftcard__guides--is_open & {
        height: auto;
        @include swap_direction(padding, 0 40px 30px 50px);
        @include opacity(1);
      }
    }
    &-link {
      color: red;
    }
  }
  &-retrieve {
    &__container {
      @include giftcard-container;
      padding-top: 40px;
    }
    &__container {
      overflow: hidden;
      width: auto;
    }
    &__sidebar {
      width: 194px;
      @include swap_direction(padding, 20px 20px 12px 20px);
      margin-bottom: 30px;
      margin-#{$rdirection}: 28px;
      background: $color-gray-background;
      float: $ldirection;
    }
    &__header {
      @include breakpoint($small-down) {
        @include h13;
      }
      @include breakpoint($medium-up) {
        @include h19;
        font-size: 16px;
      }
    }
    &__nav-header {
      display: none;
    }
    &__nav-item {
      @include h20;
      line-height: 22px;
    }
  }
}

.giftcard-balance {
  margin-bottom: 20px;
  @include breakpoint($medium-up) {
    max-width: $max-width;
    @include swap_direction(margin, 0 auto);
    min-height: 285px;
  }
  &__container {
    max-width: $max-width;
    border-top: 1px solid $color-gray-alt;
    @include breakpoint($medium-up) {
      background: $color-gray-background;
      border-top: 1px solid $color-black;
      @include swap_direction(margin, -1px auto 7px);
    }
  }
  &__header {
    @include giftcard-mobile-header;
    border-bottom: 1px solid $color-gray-alt;
    @include swap_direction(margin, 0 20px);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 60px 0 38px 0);
      @include swap_direction(margin, 0);
      border-bottom: 0;
      font-size: 25px;
      line-height: 28px;
      letter-spacing: 0.24em;
      color: $color-black;
      text-align: center;
    }
  }
  &__form {
    text-align: center;
    @include swap_direction(padding, 0 20px);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0);
    }
    #giftcard_balance {
      @include breakpoint($medium-up) {
        line-height: 32px;
        height: 32px;
      }
    }
    input[type='text'] {
      width: 100%;
    }
    &-number {
      margin-bottom: 14px;
      @include breakpoint($medium-up) {
        display: inline-block;
        min-width: 204px;
        @include swap_direction(margin, 0);
      }
    }
    &-pin {
      margin-bottom: 14px;
      @include breakpoint($medium-up) {
        display: inline-block;
        @include swap_direction(margin, 0 0 0 12px);
        min-width: 204px;
      }
    }
    &-button {
      @include breakpoint($medium-up) {
        display: inline-block;
        margin-#{$ldirection}: 24px;
        height: 32px;
      }
    }
    input[type='submit'].giftcard-balance__form-submit {
      @include breakpoint($small-down) {
        width: 100%;
        margin-bottom: 20px;
      }
      @include input-button--secondary;
      line-height: 32px;
      height: 32px;
      vertical-align: top;
    }
  }
  &__remaining {
    text-align: center;
    font-weight: normal;
    letter-spacing: 0.04em;
    color: $color-black;
    @include swap_direction(padding, 20px);
    font-size: 20px;
    font-family: $body-font;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 38px 0 0 0);
      font-size: 37px;
      line-height: 43px;
    }
  }
  &__amount-text {
  }
  &__amount {
    color: $color-pink;
  }
  &__purchase {
    // mobile only
    @include breakpoint($small-down) {
      @include link-reset;
      @include icon('arrow_$rdirection', after, true);
      @include giftcard-mobile-header;
      position: relative;
      display: block;
      background: $color-nude;
      @include swap_direction(padding, 0 20px);
      border-top: 1px solid $color-gray-alt;
      border-bottom: 1px solid $color-gray-alt;
      &:after {
        position: absolute;
        top: 0;
        #{$rdirection}: 20px;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}
