/*
 * Due to how ios bottom drawer works. We need to keep the drawer open
 * at all times for any modal with a bottom fixed button.
 */
html.fix-ios-bottom-drawer {
  &,
  & body {
    @include breakpoint($medium-down) {
      height: 100%;
    }
  }
}

/*
 * Keep background from scrolling. Note that the fix for ios has to be done via
 * javascript.
 */

@mixin input-submit {
  display: block;
  width: 100%;
  margin-top: 20px;
  border: none;
}

html.no-scroll {
  &,
  & body {
    overflow: hidden;
  }
}

.account-overlay,
.email-overlay {
  width: 370px;
  text-align: $ldirection;
  background: $color-white;
  border: 1px solid $color-gray-tooltip;
  .icon-close {
    position: absolute;
    #{$rdirection}: 8px;
    top: 8px;
    color: $color-gray;
  }
  input[type='submit'] {
    @include input-submit;
  }
  .account-overlay__next {
    background: $color-black;
    color: $color-white;
    font-size: 14px;
    .account-overlay__next-trigger {
      @include input-submit;
      @include swap_direction(margin, 0);
      margin-top: 20px;
      text-transform: uppercase;
      background: $color-white;
      border: none;
      color: $color-black;
      display: block;
      width: 100%;
    }
  }
  .account-overlay__section,
  .overlay__container--email {
    @include swap_direction(padding, 20px 25px);
  }
  .account-inline,
  .overlay__container--email {
    font-size: 14px;
    .account-inline__title {
      line-height: 1em;
      margin-bottom: 20px;
    }
    .field-container {
      margin-bottom: 10px;
    }
    .link--regular {
      padding-bottom: 0;
    }
    input[type='text'],
    input[type='password'] {
      width: 100%;
    }
  }
}

.email-overlay {
  input[type='text'] {
    margin-top: 20px;
  }
}

.account-overlay {
  .account-register__field-container {
    input[type='text'],
    input[type='password'] {
      width: 50%;
    }
    label {
      margin-#{$ldirection}: 12px;
      float: $rdirection;
      margin-top: 4px;
    }
  }
}

.tooltipster-base.overlay {
  .tooltipster-arrow {
    z-index: 9999;
    height: 0;
    span {
      width: 25px;
      height: 12px;
      background: image-url('https://m.bobbibrowncosmetics.ca/media/images/global/tooltip-arrow.png');
      background-repeat: no-repeat;
      border: none !important;
      top: -11px;
    }
  }
}
