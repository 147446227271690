// offers
@mixin offers-callout {
  font-family: $body-font-bold;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $color-pink;
}

@mixin offers-header {
  font-family: $body-font-bold;
  font-weight: normal;
  font-size: 37px;
  line-height: 46px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin offers-text {
  font-family: $body-font;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.06em;
  margin-top: 20px;
}

@mixin offers-sub-text {
  font-family: $body-font;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06em;
  margin-top: 14px;
}

.section-offers .node-page {
  @include swap_direction(margin, 0 0 40px 0);
  @include breakpoint($medium-up) {
    max-width: $max-width;
    @include swap_direction(margin, 20px auto 40px auto);
  }
}

// offers everyday
.offers-everyday {
  @include breakpoint($large-up) {
    width: $max-width;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    display: block;
  }
  @include breakpoint($small-down) {
    display: none;
  }
  background: $color-nude;
  @include swap_direction(padding, 0 20px 9px 20px);
  min-height: 177px;
  &__header {
    @include h24;
    line-height: 48px;
    text-align: center;
    border-bottom: 1px solid $color-gray-dark;
    margin-bottom: 5px;
  }
  &__items {
    @include swap_direction(margin, 0 1%);
    min-height: 114px;
    width: 98%;
    overflow-y: none;
  }
  &__item {
    text-align: center;
    float: $ldirection;
    @include breakpoint($small-down) {
      float: none;
      width: 100% !important; /* override width from slick slider */
    }
  }
  &__item-inner {
    position: relative;
    @include swap_direction(padding, 0 20px 24px 20px);
    border-#{$rdirection}: 1px solid $color-gray-dark;
    min-height: 114px;
    @include breakpoint($small-down) {
      border-#{$rdirection}: 0;
    }
  }
  &__item-header {
    @include h25;
    padding-top: 5px;
  }
  &__item-sub-header {
  }
  &__item-link {
    position: absolute;
    display: block;
    width: 100%;
    bottom: 8px;
    #{$ldirection}: 0;
    text-transform: uppercase;
  }
  // Slick
  .slick-prev,
  .slick-next {
    height: auto;
    &:before {
      @include transition(color 0.3s ease);
      font-size: 34px;
    }
  }
  .slick-prev {
    #{$ldirection}: -3px;
  }
  .slick-next {
    #{$rdirection}: -9px;
    &:before {
      position: static;
    }
  }
}

// offers hero
.offers-hero {
  @include swap_direction(padding, 20px 16px 40px 16px);
  ul {
    margin-#{$ldirection}: 24px;
  }
  ul li {
    list-style-position: outside;
    line-height: 27px;
  }
  li {
    list-style: disc;
  }
  @include breakpoint($medium-only) {
    width: 100%;
  }
  @include breakpoint($large-up) {
    width: $max-width;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    @include swap_direction(padding, 34px 16px 60px 16px);
  }
  &__header {
    @include h13;
    font-size: 20px;
    margin-bottom: 34px;
    @include breakpoint($small-down) {
      line-height: 25px;
      margin-bottom: 5px;
    }
  }
  &__container {
    @include clearfix;
    @include breakpoint($small-down) {
      position: relative;
    }
  }
  &__description {
    @include breakpoint($medium-only) {
      float: $ldirection;
      width: 60%;
    }
    @include breakpoint($large-up) {
      float: $ldirection;
      width: 50%;
      @include swap_direction(padding, 0 0 11px 30px);
    }
  }
  &__callout {
    @include offers-callout;
    @include breakpoint($small-down) {
      font-size: 4vw;
      margin-top: 5px;
      text-align: center;
    }
  }
  &__title {
    @include offers-header;
    @include breakpoint($small-down) {
      font-size: 7vw;
      text-align: center;
    }
  }
  &__text {
    @include offers-text;
    @include breakpoint($small-down) {
      margin-top: 10px;
    }
  }
  &__sub-text {
    @include offers-sub-text;
  }
  &__actions {
    margin-top: 20px;
    @include breakpoint($small-down) {
      @include clearfix;
      display: flex;
      flex-direction: column;
    }
    @include breakpoint($medium-up) {
      margin-top: 30px;
    }
    a.offers-hero__link {
      @include breakpoint($small-down) {
        border: 2px solid #000;
        margin-top: 10px;
        order: 2;
        @include swap_direction(padding, 10px);
        text-align: center;
      }
    }
    a.offers-hero__button {
      @include breakpoint($small-down) {
        order: 1;
      }
    }
  }
  &__link {
    @include l1;
    float: $ldirection;
    @include breakpoint($medium-up) {
      float: none;
      margin-top: 9px;
    }
  }
  &__button {
    @include button--secondary;
    float: $rdirection;
    margin-top: 8px;
    @include breakpoint($medium-up) {
      float: none;
      @include swap_direction(margin, 0 0 0 45px);
    }
    @include breakpoint($small-down) {
      clear: both;
      float: $ldirection;
      margin-top: 15px;
    }
  }
  &__image-container {
    @include swap_direction(padding, 20px 0 0 0);
    @include breakpoint($medium-only) {
      width: 40%;
      float: $rdirection;
    }
    @include breakpoint($large-up) {
      float: $ldirection;
      width: 50%;
      @include swap_direction(padding, 0 20px 20px 20px);
    }
    @include breakpoint($small-down) {
      display: none;
    }
  }
  &__image {
    display: block;
    width: 100%;
    @include breakpoint($small-down) {
      margin-top: 15px;
    }
    &.mobile {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  &__dropdown {
    display: none;
    position: relative;
    clear: both;
    padding-top: 16px;
    @include breakpoint($small-down) {
      margin-top: 20px;
    }
  }
  &__carrot {
    position: absolute;
    top: 0;
    #{$ldirection}: 10%;
    width: 32px;
    height: 17px;
    background: url(https://m.bobbibrowncosmetics.ca/media/images/offers/carrot.gif) no-repeat 0 0;
    @include breakpoint($small-down) {
      #{$ldirection}: 16%;
    }
  }
  &__dropdown-content {
    @include clearfix;
    border: 1px solid $color-gray-dark;
  }
  &__dropdown-products {
    display: none;
    /*     @include swap_direction (padding, 10px 20px);
    @include breakpoint($medium-up) {
      width: 50%;
      float: $ldirection;
    } */
  }
  &__dropdown-product {
    &-header {
      font-family: $body-font-bold;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 5px;
      &-inner {
        font-family: $body-font;
      }
    }
    &-shades {
      @include clearfix;
      width: 100%;
      padding-bottom: 28px;
    }
    &-shade {
      float: $ldirection;
      width: 50%;
      @include swap_direction(padding, 4px 0);
    }
  }
  &__shade {
    @include border-radius(50%, 50%);
    float: $ldirection;
    width: 16px;
    height: 16px;
    margin-#{$rdirection}: 6px;
    &-label {
      line-height: 16px;
      padding-#{$rdirection}: 5px;
      letter-spacing: normal;
      text-transform: lowercase;
    }
  }
  &__dropdown-rules {
    @include swap_direction(margin, 10px 0);
    @include swap_direction(padding, 0 20px);
    font-family: $body-font;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.03em;
    @include breakpoint($medium-up) {
      width: 100%;
      float: $ldirection;
      /*       border-#{$ldirection}: 2px solid $color-gray-dark; */
      border-#{$ldirection}: none;
    }
  }
}

// offers details
.offers-details {
  @include breakpoint($medium-only) {
    width: 100%;
  }
  @include breakpoint($large-up) {
    width: $max-width;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  .offers-details-item:first-child {
    .offers-details-item__image-container,
    .offers-details-item__description {
      border-top: none;
    }
  }
  a.offers-details-item__link {
    @include breakpoint($small-down) {
      padding-top: 12px;
      border: 2px solid $color-black;
      .touch &,
      .touchevents & {
        padding-top: 12px;
        border: 2px solid $color-black;
      }
    }
  }
}

#welcome15-offer {
  .offers-details-item {
    &__image {
      width: auto;
    }
  }
}

.offers-details-item {
  @include clearfix;
  background: #eeebe6; //bg color to match //$color-nude;
  @include swap_direction(padding, 0 20px 30px 20px);
  min-height: 210px;
  &__image-container {
    float: $ldirection;
    @include breakpoint($medium-up) {
      width: 22%;
    }
    width: 50%;
    @include swap_direction(padding, 10px 30px 0 10px);
    border-top: 1px solid $color-gray-dark;
    @include breakpoint($small-down) {
      @include swap_direction(padding, 10px 0 0 0);
      width: 50%;
    }
  }
  &__image {
    width: 100%;
    height: auto;
  }
  &__description {
    float: $ldirection;
    width: 50%;
    padding-top: 26px;
    border-top: 1px solid $color-gray-dark;
    @include breakpoint($medium-up) {
      width: 78%;
    }
    @include breakpoint($small-down) {
      padding-top: 12px;
      width: 50%;
    }
  }
  &__callout {
    @include offers-callout;
    margin-bottom: 8px;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  &__header {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 7px;
    @include breakpoint($small-down) {
      font-size: 12px;
      line-height: 15px;
    }
  }
  &__sub-header {
    font-family: $body-font;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.06em;
    @include breakpoint($medium-up) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__text {
    @include offers-sub-text;
  }
  &__actions {
    @include clearfix;
    clear: both;
    padding-top: 23px;
    @include breakpoint($small-down) {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }
  }
  &__link {
    @include l1;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 8px 0 0 10%);
    }
    &--open {
      .offers-details-item--dropdown & {
        display: none;
      }
    }
    &--closed {
      display: none;
      .offers-details-item--dropdown & {
        display: inline;
      }
    }
    &-container {
      float: $ldirection;
      width: 50%;
      @include breakpoint($medium-up) {
        width: 22%;
      }
      @include breakpoint($small-down) {
        float: none;
        margin-top: 10px;
        order: 2;
        width: 100%;
        a {
          display: block;
          text-align: center;
        }
      }
    }
  }
  &__button {
    @include button--secondary;
    float: $rdirection;
    margin-top: 8px;
    @include breakpoint($medium-up) {
      margin-top: none;
      float: none;
    }
    @include breakpoint($small-down) {
      display: block;
      float: none;
      margin-top: 15px;
    }
    &-container {
      float: $ldirection;
      width: 50%;
      @include breakpoint($medium-up) {
        width: 78%;
      }
      @include breakpoint($small-down) {
        float: none;
        order: 1;
        width: 100%;
      }
    }
  }
  &__dropdown {
    display: none;
    @include swap_direction(padding, 20px);
    font-size: 11px;
    .offers-details-item--dropdown & {
      display: block;
    }
  }
}

// offers vip
.offers-vip {
  margin-top: 10px;
  &__content {
    @include swap_direction(padding, 36px 20px 0 20px);
    max-width: $max-width;
    @include swap_direction(margin, 0 auto);
    .offers-vip--sized & {
      position: relative;
    }
    &-container {
      width: 100%;
      background: #f6f5f1; //close to $color-nude, has to match image bg
    }
  }
  &__header {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.28em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 7px;
  }
  &__image {
    display: block;
    width: 100%;
    &-container {
      @include breakpoint($medium-up) {
        float: $ldirection;
        width: 50%;
        .offers-vip--sized & {
          float: none;
          position: absolute;
          bottom: 0;
          #{$ldirection}: 0;
        }
      }
    }
  }
  &__description {
    text-align: center;
    @include swap_direction(padding, 20px 5px 60px 5px);
    @include breakpoint($medium-up) {
      float: $ldirection;
      width: 50%;
      @include swap_direction(padding, 86px 5px 80px 5px);
      .offers-vip--sized & {
        width: 100%;
        @include swap_direction(padding, 86px 5px 80px 50%);
      }
    }
  }
  &__description-header {
    font-family: $body-font;
    font-weight: normal;
    font-size: 38px;
    line-height: 44px;
    letter-spacing: 0.01em;
  }
  &__description-text {
    margin-top: 14px;
    font-size: 18px;
    line-height: 26px;
  }
  &__actions {
    margin-top: 20px;
    @include breakpoint($medium-up) {
      margin-top: 55px;
    }
  }
  &__button {
    @include button;
    @include breakpoint($small-down) {
      width: 100%;
      line-height: 40px;
      height: 40px;
    }
  }
  &__link {
    @include link($color-black, $color-pink);
    @include swap_direction(margin, 4px 0 0 15px);
    @include breakpoint($small-down) {
      @include swap_direction(margin, 20px 0 0 0);
      display: block;
    }
  }
  &__footer {
    background: $color-black;
    color: $color-white;
    margin-top: 60px;
    @include swap_direction(padding, 40px);
    text-align: center;
  }
  &__footer-header {
    font-family: $body-font-light;
    font-weight: normal;
    font-size: 38px;
    line-height: 44px;
    letter-spacing: 0.024em;
  }
  &__footer-link {
    @include link($color-white, $color-white);
    margin-top: 26px;
  }
}

//offer detail
.offer-specific__container {
  // offers class to generic, add unique class
  .offer {
    max-width: $max-width;
    @include swap_direction(margin, 0 auto);
    overflow: hidden;
    padding-bottom: 20px;
    &__breadcrumb {
      max-width: $max-width;
      @include swap_direction(margin, 0 auto);
      height: 48px;
      line-height: 48px;
      background: $color-gray-background;
      @include swap_direction(padding, 0 30px);
      margin-bottom: 30px;
      @include breakpoint($medium-up) {
        margin-bottom: 50px;
      }
      &-container {
        width: 100%;
        background: $color-gray-background;
        @include breakpoint($medium-up) {
          margin-top: 5px;
        }
      }
      &-link {
        @include icon('arrow_left', 'before', false, false, 22px);
        font-family: $body-font-bold;
        text-transform: uppercase;
        text-decoration: none;
        &:before {
          @include swap_direction(padding, 0 4px 2px 0);
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    &__container {
      @include swap_direction(padding, 0 30px);
    }
    &__header {
      @include h13;
      font-size: 20px;
      margin-bottom: 34px;
      @include breakpoint($small-down) {
        line-height: 25px;
      }
    }
    &-hero {
      // offer-hero
      @include clearfix;
      &__description {
        @include breakpoint($medium-up) {
          float: $ldirection;
          width: 50%;
          // path does not work
          // with added class for specificity
          // see below
          // .offer--returns & {
          //   width: 70%;
          // }
          // .offer--shipping & {
          //   width: 59%;
          // }
        }
      }
      &__callout {
        @include offers-callout;
      }
      &__header {
        @include offers-header;
      }
      &__text {
        @include offers-text;
        margin-bottom: 0px;
        p {
          @include swap_direction(margin, 0px 0px 50px 0px);
        }
        ul {
          margin-#{$ldirection}: 24px;
        }
        ul li {
          list-style-position: outside;
          list-style: none;
          padding-#{$ldirection}: 1em;
          text-indent: -22px;
        }
        li:before {
          content: '\00B7';
          font-size: 32px;
          margin-#{$rdirection}: 0.3em;
        }
      }
      &__actions {
        @include clearfix;
      }
      &__link {
        text-transform: uppercase;
        font-size: 14px;
        text-decoration: none;
        color: #000000;
        font-decoration: none;
        letter-spacing: 3px;
        font-weight: bold;
        display: inline;
        border-bottom: 2px solid #000000;
        padding-bottom: 4px;
        @include breakpoint($medium-up) {
          float: $ldirection;
          margin-top: 9px;
        }
      }
      &__link:hover {
        color: #ff526c;
        border-bottom-color: #ff526c;
      }
      &__button {
        @include button--secondary;
        margin-#{$ldirection}: 40px;
        @include breakpoint($small-down) {
          width: 100%;
          @include swap_direction(margin, 20px 0 0 0);
        }
      }
      &__image-container {
        @include swap_direction(padding, 20px 0 0 0px);
        width: 50%;
        @include breakpoint($medium-up) {
          float: $ldirection;
          // path does not work
          // with added class for specificity
          // see below
          // .offer--returns & {
          //   width: 30%;
          //   @include swap_direction (padding, 0 0 0 35px);
          // }
          // .offer--shipping & {
          //   width: 41%;
          // }
        }
      }
      &__image {
        width: 100%;
      }
      //offer details
      &__dropdown {
        display: none;
        position: relative;
        clear: both;
        padding-top: 16px;
        @include breakpoint($small-down) {
          margin-top: 20px;
        }
      }
      &__carrot {
        position: absolute;
        top: 0;
        z-index: 999;
        margin-bottom: -1px;
        #{$ldirection}: 10%;
        width: 32px;
        height: 17px;
        background: url(https://m.bobbibrowncosmetics.ca/media/images/offers/carrot.gif) no-repeat 0 0;
        @include breakpoint($small-down) {
          #{$ldirection}: 20%;
        }
      }
      &__dropdown-content {
        @include clearfix;
        border: 1px solid $color-gray-dark;
        .offer__text {
          @include swap_direction(margin, 10px 0);
          @include swap_direction(padding, 0 20px);
          font-family: $body-font;
          font-weight: normal;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.03em;
          @include breakpoint($medium-up) {
            width: 100%;
            float: $ldirection;
            border-#{$ldirection}: none;
          }
        }
      }
    }
    // Double Offer
    &-double__description {
    }
    &--1 {
      @include swap_direction(padding, 0 42px 24px 0);
    }
    &--2 {
      @include swap_direction(padding, 0 0 24px 42px);
      border-#{$ldirection}: 1px #000 solid;
    }
    &-options {
      width: 50%;
      float: $ldirection;
      &__image-container {
        @include swap_direction(padding, 20px 0 50px 0);
      }
    }
    &__categories {
      @include clearfix;
      @include swap_direction(padding, 30px 0 0 0);
    }
    &__category {
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        float: $ldirection;
        text-align: center;
        margin-bottom: 70px;
      }
      &--1 {
        @include breakpoint($medium-up) {
          width: 28%;
          @include swap_direction(padding, 0 26px 0 19px);
        }
      }
      &--2 {
        @include breakpoint($medium-up) {
          width: 44%;
          @include swap_direction(padding, 0 35px 0 27px);
        }
      }
      &--3 {
        @include breakpoint($medium-up) {
          width: 28%;
        }
      }
    }
    &__category-link {
      display: block;
      height: 100%;
      padding-bottom: 50px; // 50px for button
      @include breakpoint($medium-up) {
        position: relative;
      }
    }
    &__category-image {
      display: block;
      width: 100%;
      @include swap_direction(padding, 0 15%);
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0);
      }
    }
    &__category-button {
      @include button--outline;
      width: 100%;
      margin-top: 20px;
      @include breakpoint($medium-up) {
        position: absolute;
        bottom: 0;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        @include swap_direction(margin, 0 auto);
        width: auto;
      }
    }
  }
  // alternates
  .offer--returns {
    .offer-hero__description {
      @include breakpoint($medium-up) {
        width: 70%;
      }
    }
    .offer-hero__image-container {
      @include breakpoint($medium-up) {
        width: 30%;
        @include swap_direction(padding, 0 0 0 35px);
      }
    }
  }
  .offer--shipping {
    .offer-hero__description {
      @include breakpoint($medium-up) {
        width: 59%;
      }
    }
    .offer-hero__image-container {
      @include breakpoint($medium-up) {
        width: 41%;
      }
    }
  }
}

// Jira #408 - Bobbi / US / Offer Pop-Up At Checkout
/* Offers inside a colorbox */

/* US */

.en_US #cboxContent {
  .offers-everyday {
    width: 99%;
    .offers-everyday__items {
      display: block;
      @include swap_direction(margin, 0);
      width: 100%;
      .offers-everyday__item {
        width: 33%;
        .offers-everyday__item-inner {
          @include swap_direction(padding, 0 18px 24px);
          .offers-everyday__item-header {
            font-size: 14px;
          }
        }
        &:last-child {
          .offers-everyday__item-inner {
            border: none;
          }
        }
      }
    }
  }
}
/* CA */

.en_CA #cboxContent {
  .offers-everyday {
    width: 99%;
    .offers-everyday__items {
      display: block;
      @include swap_direction(margin, 0);
      width: 100%;
      .offers-everyday__item {
        width: 33%;
        &:last-child {
          .offers-everyday__item-inner {
            border: none;
          }
        }
      }
    }
  }
}
/* Small screens */

@media only screen and (min-width: 359px) and (max-width: 420px) {
  .offer-code-panel .offer-code__one-offer-only {
    font-size: 13px;
    .offer-code__see-all-offers {
      border-#{$ldirection}: none;
      border-#{$rdirection}: none;
      border-top: none;
      font-size: 11px;
      letter-spacing: 0;
      @include swap_direction(padding, 0);
    }
  }
}
/* Readjust the "More Details" link font size for screens smaller than 500px wide */

@media only screen and (max-width: 500px) {
  .offers-hero__link,
  .offers-details-item__link {
    font-size: 10px;
  }
  .offers-details-item__sub-header {
    font-size: 14px;
    line-height: 16px;
  }
}
