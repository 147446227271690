.checkout-confirmation-page {
  .registration-panel {
    @include breakpoint($medium-up) {
      border-top: 1px solid $color-light-gray;
    }
    &__content {
      &__left {
        padding: 0 12px;
        margin-bottom: 25px;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0 32px 0 0);
          width: 46%;
          float: $ldirection;
          padding-#{$rdirection}: 32px;
        }
        &__fields {
          padding-#{$ldirection}: 0;
          .checkout-registration {
            &__email-address {
              &-title {
                padding: 0;
                border-bottom: none;
                margin-bottom: 4px;
                letter-spacing: 0;
              }
              .example-user-email {
                .bold {
                  display: block;
                  text-transform: uppercase;
                }
              }
            }
            &__password {
              @include breakpoint($medium-up) {
                margin-top: 25px;
              }
              input[type='password'] {
                border-color: $color-warm-grey;
              }
            }
            &__agreements {
              @include breakpoint($medium-up) {
                display: none;
              }
              padding-bottom: 15px;
              font-size: 13px;
              color: $color-warm-grey;
              &__link {
                color: $color-warm-grey;
              }
            }
          }
        }
        .checkout__panel__guest {
          &__title {
            @include breakpoint($medium-up) {
              padding-top: 41px;
            }
            padding-top: 12px;
            font-size: 20px;
            font-family: $body-font-bold;
            text-transform: uppercase;
            margin-bottom: 2px;
          }
          &__subtitle {
            font-family: $body-font-bold;
            margin-bottom: 5px;
            font-size: 20px;
            text-transform: uppercase;
          }
          &__signup__text {
            margin: 5px 0 20px;
            font-size: 15px;
          }
        }
        .show-password__link {
          position: absolute;
          bottom: 10px;
          #{$rdirection}: 13px;
          color: $color-gray-border;
        }
        .registration__checkbox {
          &__link {
            text-decoration: underline;
            color: $color-warm-grey;
          }
          .label {
            color: $color-warm-grey;
            &-content {
              display: inline-block;
              vertical-align: top;
              width: 88%;
              padding-top: 2px;
              font-size: 13px;
            }
          }
        }
        .registration-form-container {
          float: none;
          width: 100%;
        }
        .continue-button-wrapper {
          @include breakpoint($medium-up) {
            margin-top: 20px;
          }
          padding-top: 0;
          .form-submit {
            @include breakpoint($medium-up) {
              padding: 2px 24px;
              width: auto;
            }
            font-size: 13px;
            width: 100%;
            height: inherit;
            background-color: $color-black;
          }
        }
      }
      &__right {
        padding: 0 12px;
        @include breakpoint($medium-up) {
          padding: 45px 0 0;
          width: 45%;
          float: $rdirection;
        }
        .checkout__panel__guest {
          &__img {
            &__container {
              @include breakpoint($medium-up) {
                height: 420px;
              }
              background-image: url('https://m.bobbibrowncosmetics.ca/media/export/cms/loyalty/checkout_loyalty_background.jpg');
              background-size: 100%;
              background-repeat: no-repeat;
              height: 263px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .loyalty__section {
    padding: 0 8px;
    @include breakpoint($medium-up) {
      border-top: 1px solid $color-light-gray;
      padding: 0;
    }
    &__content {
      @include breakpoint($medium-up) {
        width: 46%;
        float: $ldirection;
      }
      &__options {
        &__title {
          @include breakpoint($medium-up) {
            padding-top: 100px;
          }
          padding-top: 15px;
          font-size: 20px;
          font-family: $body-font-bold;
          line-height: 1;
        }
        &__signuptext {
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 20px 65px 20px 0);
          }
          @include swap_direction(padding, 10px 30px 30px 0);
          font-size: 14px;
        }
        &__join {
          margin: 25px 0;
          &__link {
            @include breakpoint($medium-up) {
              width: 36%;
            }
            padding: 7px 0;
            width: 100%;
            height: inherit;
            font-size: 13px;
            &:hover,
            &:focus,
            &:visited {
              background-color: $color-black;
            }
            &.button {
              &--disabled {
                background-color: $color-light-gray;
              }
            }
          }
        }
        &__checkbox {
          color: $color-warm-grey;
          .label-content {
            display: inline-block;
            width: 83%;
            vertical-align: top;
            padding-top: 2px;
          }
          &__link {
            color: $color-warm-grey;
          }
        }
      }
      &__terms {
        @include breakpoint($medium-up) {
          padding: 16px 0 0;
        }
        padding: 8px 0 35px;
        font-size: 12px;
        color: $color-grey-footer;
        &__link {
          cursor: pointer;
          color: $color-grey-footer;
          text-decoration: underline;
        }
      }
    }
    &__img {
      @include breakpoint($medium-up) {
        width: 45%;
        float: $rdirection;
        padding-top: 45px;
      }
      &__container {
        background-image: url('https://m.bobbibrowncosmetics.ca/media/export/cms/loyalty/checkout_loyalty_background.jpg');
        background-size: 100%;
        background-repeat: no-repeat;
        height: 320px;
        margin-bottom: 30px;
      }
    }
  }
}
