.page-products-makeup-auto-replenishment {
  @include breakpoint($medium-up) {
    .page-header__title {
      font-size: 50px;
    }
  }
  .page-header__subtitle {
    .subtitle {
      font-size: 26px;
    }
    .steps {
      font-size: 16px;
      line-height: 1.25em;
      overflow: auto;
      @include swap_direction(margin, 28px 0 12px);
    }
    .step {
      width: 25%;
      height: 200px;
      float: $ldirection;
      padding-top: 70px;
      border-top: solid 1px $color-gray-border;
      position: relative;
      &:nth-child(n + 2) {
        border-#{$ldirection}: solid 1px $color-gray-border;
      }
      .step_name {
        font-family: $body-font-bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        &:before {
          position: absolute;
          top: 20px;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
        }
      }
      &:nth-child(1) .step_name:before {
        content: url(https://m.bobbibrowncosmetics.ca/media/export/cms/mpp/Auto_replenishment-1.png);
      }
      &:nth-child(2) .step_name:before {
        content: url(https://m.bobbibrowncosmetics.ca/media/export/cms/mpp/Auto_replenishment-2.png);
      }
      &:nth-child(3) .step_name:before {
        content: url(https://m.bobbibrowncosmetics.ca/media/export/cms/mpp/Auto_replenishment-3.png);
      }
      &:nth-child(4) .step_name:before {
        content: url(https://m.bobbibrowncosmetics.ca/media/export/cms/mpp/Auto_replenishment-4.png);
      }
    }
  }
  .cancel_link a {
    color: black;
  }
}
