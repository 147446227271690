.soothing-milk {
  overflow: auto;
  h1 {
    font-family: 'MillerBanner Light';
    font-size: 80px;
    text-align: center;
    line-height: 0.8em;
    letter-spacing: 0px;
  }
  .product_collection_hero {
    background-position-x: 50%;
    height: 635px;
    &__body {
      width: 50%;
      top: 20%;
      position: relative;
      text-align: center;
    }
    &__subtitle {
      font-size: 22px;
      text-transform: uppercase;
      text-align: center;
      @include swap_direction(margin, 0 auto 20px);
    }
    &__html_block {
      font-size: 18px;
      line-height: 1.5em;
      max-width: 380px;
      @include swap_direction(margin, 0 auto);
    }
    &__content-item {
    }
  }
  .product-atb {
    max-width: 488px;
    text-align: center;
    float: $ldirection;
    @include swap_direction(margin, 160px 0 0 20px);
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 160px 0 0 130px);
    }
    .product-palette-atb {
      &__callout {
        font-family: 'MillerBanner Light Italic';
        font-size: 45px;
        font-style: oblique;
        text-transform: uppercase;
        line-height: 1em;
        position: relative;
        &:before {
          position: absolute;
          top: -86px;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
        }
      }
      &__product-name {
        font-family: $brandon-text-bold;
        font-size: 25px;
        text-transform: uppercase;
        line-height: 1.25em;
        max-width: 512px;
        margin-bottom: 20px;
      }
    }
    p {
      font-size: 18px;
      line-height: 1.5em;
    }
  }
  .product-image {
    float: $rdirection;
    @include swap_direction(margin, 40px 30px 20px 0);
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 40px 150px 20px 0);
    }
  }
  .button--outline {
    border: 2px solid $color-black;
    color: $color-black;
    &:hover {
      border: 2px solid $color-pink;
      color: $color-pink;
    }
  }
  .block_bobbi-tip {
    text-align: center;
    line-height: 1.5em;
    width: 325px;
    @include swap_direction(padding, 12px);
    background-color: $color-gray-background;
    float: $rdirection;
    margin-#{$rdirection}: 40px;
    @include breakpoint($landscape-up) {
      width: 425px;
    }
    p {
      font-size: 14px;
      @include swap_direction(margin, 0);
    }
    .text-block_title {
      font-family: $brandon-text-bold;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
  .block_soothing-cleansing-milk .product-palette-atb__callout:before {
    content: url(https://m.bobbibrowncosmetics.ca/media/export/cms/collections/soothing_milk/BB_SS17_CMS_SoothingCleansingMilk_Sun.jpg);
  }
  .block_soothing-cleansing-oil {
    .product-palette-atb__callout:before {
      content: url(https://m.bobbibrowncosmetics.ca/media/export/cms/collections/soothing_milk/BB_SS17_CMS_SoothingCleansingMilk_Moon.jpg);
    }
    .product-image {
      @include swap_direction(margin, 0 150px 20px 0);
    }
  }
  .block_extra-balm-rinse {
    @include swap_direction(margin, 40px 0px 20px 0);
    border-top: 1px solid $color-gray-border;
    .product-image {
      @include swap_direction(margin, -200px 0px 20px 0);
      float: $ldirection;
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, -200px 0px 20px 60px);
      }
    }
    .product-atb {
      @include swap_direction(margin, 80px 40px 0 0);
      float: $rdirection;
      max-width: 300px;
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 80px 0 0 0);
        max-width: 488px;
      }
      .product-palette-atb__callout {
        font-family: $brandon-text-bold;
        font-size: 15px;
        font-style: initial;
        text-transform: uppercase;
        margin-bottom: 6px;
        position: relative;
        &:before {
          content: '';
          background: #000;
          width: 50%;
          height: 1px;
          display: inline-block;
          position: absolute;
          top: 6px;
          #{$ldirection}: 25%;
        }
        .inner {
          background: #fff;
          display: inline-block;
          position: relative;
          @include swap_direction(padding, 0 7px 0 9px);
        }
      }
    }
  }
}
