.corrector-concealer {
  p {
    font-size: 18px;
    margin-bottom: 9px;
    .head {
      font-family: $body-font-bold;
    }
  }
  &.product_collection_hero_page {
    max-width: 1024px;
    @include swap_direction(padding, 40px 20px);
    @include swap_direction(margin, 0 auto);
  }
  .product_collection_hero {
    background-repeat: no-repeat;
    background-position: -9% center;
    @include breakpoint($large-range) {
      background-position: 7% center;
    }
    &__body {
      width: 403px;
      float: $rdirection;
      margin-top: 72px;
      @include breakpoint($large-range) {
        width: 477px;
        margin-#{$rdirection}: 60px;
      }
      h1,
      h2 {
        line-height: 1.25em;
        text-align: $ldirection;
        position: relative;
      }
    }
    &__subtitle {
      text-align: $ldirection;
      position: relative;
      width: 400px;
      padding-#{$rdirection}: 98px;
      @include swap_direction(margin, 0);
      line-height: 1.25em;
    }
    &__subtitle:after {
      content: url(https://m.bobbibrowncosmetics.ca/media/export/cms/evergreen/corrector-concealer/corrector-concealer_shipping-seal.jpg);
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
    }
    &__html_block {
      font-size: 18px;
      width: 400px;
      line-height: 1.75em;
      margin-top: 70px;
    }
  }
  .block_container {
    &__title {
      font-family: $body-font-bold;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 3px;
      position: relative;
      @include swap_direction(margin, 34px 0 27px 0);
      text-align: center;
      .inner {
        background: #ffffff;
        display: inline-block;
        position: relative;
        @include swap_direction(padding, 0 7px 0 9px);
      }
    }
    &__title:before {
      content: '';
      background: #000000;
      width: 100%;
      height: 1px;
      display: inline-block;
      position: absolute;
      bottom: 9px;
      #{$ldirection}: 0;
    }
  }
  .how-they-work {
    .block_container {
      &__content {
        @include swap_direction(padding, 20px);
        background-color: #eff0f0;
        overflow: auto;
      }
    }
  }
  .cc {
  }
  .text-block {
    width: 50%;
    float: $ldirection;
    @include swap_direction(padding, 20px);
    &:nth-child(2) {
      padding-#{$ldirection}: 40px;
      border-#{$ldirection}: 1px solid #d0d0d0;
    }
    &_title {
      font-family: $body-font-bold;
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 18px;
    }
    &_body {
      p {
        font-size: 16px;
        margin-bottom: 12px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
      .head {
        font-family: $body-font-bold;
      }
    }
  }
  .cc {
    &_product {
      img {
        float: $ldirection;
      }
      &__name {
        font-family: $body-font-bold;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 18px;
        line-height: 1.5em;
      }
      &__product {
        float: $rdirection;
        width: 64%;
        padding-#{$rdirection}: 0;
        @include swap_direction(margin, 60px 0);
        @include breakpoint($large-range) {
          width: 70%;
          padding-#{$rdirection}: 90px;
        }
        p {
          font-size: 16px;
        }
      }
      &__boxed {
        font-sized: 14px;
        background-color: #eff0f0;
        p {
          @include swap_direction(padding, 20px);
        }
      }
      &__qs {
        &-details {
          margin-top: 30px;
        }
      }
      &__price {
        font-family: $body-font-bold;
        font-size: 18px;
        @include swap_direction(padding, 9px 0);
        @include breakpoint($large-range) {
          float: $ldirection;
        }
      }
    }
    &_qs_button {
      margin-top: 20px;
      @include breakpoint($large-range) {
        float: $ldirection;
        margin-#{$ldirection}: 20px;
        margin-top: 0;
      }
    }
    &_section_head {
      font-family: $body-font-bold;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 3px;
      position: relative;
      @include swap_direction(margin, 34px 0 27px 0);
      text-align: center;
      .inner {
        background: #ffffff;
        display: inline-block;
        position: relative;
        @include swap_direction(padding, 0 7px 0 9px);
      }
      &:before {
        content: '';
        background: #000000;
        width: 100%;
        height: 1px;
        display: inline-block;
        position: absolute;
        bottom: 9px;
        #{$ldirection}: 0;
      }
    }
  }
  .works-well-with {
    .product-collection-product {
      width: 50%;
      float: $ldirection;
      &:nth-child(-n + 3) .product-collection-product__product {
        text-align: center;
        padding-top: 190px;
        background-repeat: no-repeat;
        background-position: center 0;
        height: 400px;
        border-bottom: 1px solid #d0d0d0;
      }
      &:nth-child(n + 4) .product-collection-product__product {
        text-align: center;
        padding-top: 300px;
        background-repeat: no-repeat;
        background-position: center 0;
        height: 460px;
      }
      &:nth-child(n + 2):nth-child(odd) .product-collection-product__product {
        border-#{$ldirection}: 1px solid #d0d0d0;
      }
      &__product-name {
        margin-bottom: 10px;
        font-family: $body-font-bold;
        font-size: 16px;
        text-transform: uppercase;
      }
      &__description {
        display: none;
      }
      &__callout {
        font-family: $body-font-bold;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: #ff4661;
      }
      &__price {
        font-family: $body-font-bold;
        margin-#{$rdirection}: 20px;
      }
      .button--outline {
        margin-#{$ldirection}: 15px;
      }
    }
  }
}
