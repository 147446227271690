.site-email-sms-signup {
  max-width: 1024px;
  @include swap_direction(margin, 30px auto 20px auto);
  @include swap_direction(padding, 50px 90px 100px 90px);
  background: url(https://m.bobbibrowncosmetics.ca/media/images/account/email-sms-signup-envelope.gif) no-repeat center top;
  @include breakpoint($medium-down) {
    width: 100%;
  }
  @include breakpoint($small-down) {
    background: none;
    @include swap_direction(padding, 30px 1%);
    margin-top: 10px;
  }
  &__header {
    width: 600px;
    @include swap_direction(margin, 0 auto);
    margin-bottom: 10px;
    text-align: center;
    @include breakpoint($medium-down) {
      margin-bottom: 20px;
    }
    @include breakpoint($small-down) {
      width: 100%;
      @include swap_direction(padding, 60px 15px 40px);
      background: url(https://m.bobbibrowncosmetics.ca/media/images/account/email-sms-signup-envelope-small.gif) no-repeat center top;
      background-size: 95%;
      border-bottom: 2px solid $color-gray;
    }
    h1,
    .at-h2 {
      height: 40px;
      margin-bottom: 12px;
      font-family: $body-font;
      font-size: 3em;
      letter-spacing: 2px;
      line-height: 1em;
      text-transform: uppercase;
      @include breakpoint($small-down) {
        height: auto;
        margin-bottom: 15px;
        font-size: 1.7em;
      }
    }
    .at-h2 {
      font-size: 1em;
      letter-spacing: 1px;
      @include breakpoint($medium-up) {
        font-size: 1.5em;
      }
    }
    h3 {
      margin: 0;
      @include swap_direction (margin, 0);
      font-size: 1.2em;
      line-height: 1.25em;
    }
  }
  &__field {
    display: inline-block;
    width: 96%;
    margin: 10px;
    @include breakpoint($medium-down) {
      width: 45.5%;
      @include swap_direction(margin, 8px 2%);
    }
    @include breakpoint($small-down) {
      width: 96%;
    }
    @include breakpoint($medium-up) {
      width: 255px;
      margin: 6px 10px;
    }
  }
  &__required-note {
    @include swap_direction(margin, 0px 0px 0px 10px);
    @include breakpoint($medium-down) {
      width: 96%;
      @include swap_direction(margin, 0 2%);
    }
  }
  &__birthday {
    text-align: $rdirection;
    fieldset {
      @include breakpoint($medium-up) {
        margin: .35em .625em .75em;
      }
      legend {
        float: $ldirection;
        padding: 5px 0 0;
      }
    }
  }
  &__birthday fieldset
  &__birthday-label {
    display: inline-block;
    width: 60px;
    @include swap_direction(margin, 0 10px 0 0);
    @include breakpoint($medium-down) {
      width: 25%;
      float: $ldirection;
      text-align: $ldirection;
      line-height: 30px;
    }
  }
  &__month,
  &__day {
    width: 48px;
    @include swap_direction(margin, 0 4px);
    @include swap_direction(padding, 10px 8px);
    @include breakpoint($medium-down) {
      width: 17.5%;
      @include swap_direction(margin, 0 1%);
    }
  }
  &__year {
    width: 55px;
    margin-#{$ldirection}: 4px;
    @include swap_direction(padding, 10px 8px);
    @include breakpoint($medium-down) {
      width: 25%;
      margin-#{$ldirection}: 1%;
    }
  }
  &__agree {
    @include swap_direction(margin, 5px 10px 10px 10px);
    display: inline-block;
    width: 532px;
    @include breakpoint($medium-down) {
      width: 71%;
      @include swap_direction(margin, 8px 2%);
    }
    @include breakpoint($small-down) {
      width: 96%;
    }
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
    input {
      position: static;
      float: $ldirection;
      margin-top: 5px;
      width: 20px;
    }
    p.site-email-sms-signup__agree--terms {
      display: inline;
      padding-#{$ldirection}: 5px;
      margin: 0;
      width: 480px;
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 14px;
      }
    }
  }
  &__submit {
    @include swap_direction(margin, 10px);
    vertical-align: top !important;
    margin-top: 10px;
    line-height: 0;
    @include breakpoint($medium-down) {
      height: 60px;
      line-height: 60px;
      @include swap_direction(margin, 8px 2%);
    }
    @include breakpoint($small-down) {
      width: 96% !important;
      height: 40px;
      line-height: 40px;
    }
    @include breakpoint($medium-up) {
      margin-top: 5px;
    }
  }
  &__error {
    display: inline-block !important;
    width: 100%;
    @include swap_direction(margin, 60px 10px 10px 10px);
    color: $color-pink-red;
    font-family: $body-font-bold;
    text-align: $ldirection;
    @include breakpoint($medium-down) {
      width: 96%;
      @include swap_direction(margin, 30px 2% 8px 2%);
    }
    @include breakpoint($small-down) {
      margin-top: 10px;
    }
  }
  .missing-error {
    color: $color-pink-red;
    border-color: $color-pink-red;
  }
  .missing-error::-webkit-input-placeholder {
    color: $color-pink-red;
  }
  .missing-error::-moz-placeholder {
    color: $color-pink-red;
  }
  .missing-error:-moz-input-placeholder {
    color: $color-pink-red;
  }
  .missing-error:-ms-input-placeholder {
    color: $color-pink-red;
  }
}
