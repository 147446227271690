.loyalty__panel__points {
  padding: 35px 0 20px;
  border-bottom: 1px solid $color-gray;
  &__header {
    font-family: $body-font-bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.24em;
    @include breakpoint($medium-up) {
      display: none;
    }
    .seperator {
      height: 2px;
      vertical-align: middle;
      border: 1px solid $color-black;
      display: inline-block;
      width: 30px;
      @if $lr2021 {
        display: none;
      }
    }
    .header-text {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.tier-3 {
    .points__current {
      @if $lr2021 == true {
        color: $lr2021-tier-txt-color;
      } @else {
        color: $color-loyalist;
      }
    }
    .loyalty__panel__points__title__seperator,
    .loyalty__panel__points__info-section {
      @if $lr2021 == true {
        background: #755539;
        background: linear-gradient(
          90deg,
          #{$lr2021-tier-three-bg-gradient-color-1} 0%,
          #{$lr2021-tier-three-bg-gradient-color-2} 100%
        );
        color: $lr2021-tier-txt-color;
        border-color: $lr2021-tier-three-border-color;
      } @else {
        color: $color-white;
        background-color: $color-loyalist;
      }
    }
    .loyalty__panel__points__title__level {
      &.tier-3 {
        display: inline-block;
      }
    }
  }
  &.tier-2 {
    .points__current {
      @if $lr2021 == true {
        color: $lr2021-tier-txt-color;
      } @else {
        color: $color-enthusiast;
      }
    }
    .loyalty__panel__points__title__seperator,
    .loyalty__panel__points__info-section {
      @if $lr2021 == true {
        background-color: $lr2021-tier-two-bg-color;
        color: $lr2021-tier-txt-color;
        border-color: $lr2021-tier-two-border-color;
      } @else {
        background-color: $color-enthusiast;
        color: $color-white;
      }
      .tier-2 {
        display: inline;
      }
    }
    .loyalty__panel__points__title__level {
      &.tier-2 {
        display: inline-block;
      }
    }
  }
  &.tier-1 {
    .loyalty__panel__points__info-section {
      .tier-1 {
        display: inline;
      }
    }
    .loyalty__panel__points__title__level {
      &.tier-1 {
        display: inline-block;
      }
    }
  }
  &__title {
    @include breakpoint($medium-up) {
      text-align: $ldirection;
      margin-bottom: 0;
    }
    font-family: $body-font-bold;
    font-size: 14px;
    text-transform: uppercase;
    display: none;
    text-align: center;
    margin-bottom: 20px;
    &__header,
    &__seperator,
    &__level {
      display: inline-block;
      @include breakpoint($medium-up) {
        text-align: $ldirection;
        vertical-align: middle;
      }
    }
    &__level {
      display: none;
    }
    &__header {
      display: block;
      @include breakpoint($medium-up) {
        display: inline-block;
      }
    }
    &__seperator {
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 0 2px 5px);
      }
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: $color-fantastic;
      @if $lr2021 == true {
        display: none;
      }
    }
  }
  &__left {
    margin: 30px 0;
    text-align: center;
    @include breakpoint($medium-up) {
      margin: 0;
      text-align: $ldirection;
      width: 48%;
      display: inline-block;
      vertical-align: top;
      .loyalty__panel__points__title {
        display: block;
      }
    }
    @if ($lr2021) {
      .points {
        @include breakpoint($large-up) {
          display: flex;
          justify-content: unset;
          align-items: center;
        }
      }
    }
  }
  &__right {
    padding: 30px 0 45px;
    position: relative;
    border-top: 1px solid $color-gray-light;
    .loyalty__panel__points__title {
      display: block;
      letter-spacing: 0.1em;
    }
    @include breakpoint($medium-up) {
      padding: 0;
      border-top: none;
      width: 51%;
      display: inline-block;
      vertical-align: top;
      .loyalty__panel__points__title {
        display: none;
      }
    }
  }
  &__expiry {
    margin: 15px 0;
    font-size: 15px;
  }
  &__info-section {
    padding: 10px;
    margin-bottom: 5px;
    @if $lr2021 == true {
      background-color: $lr2021-tier-one-bg-color;
      color: $lr2021-tier-txt-color;
      border-right: 12px solid $lr2021-tier-one-border-color;
      @include breakpoint($medium-up) {
        border-right: none;
        border-bottom: 12px solid $lr2021-tier-one-border-color;
      }
    } @else {
      background-color: $color-fantastic;
    }
    @include breakpoint($medium-up) {
      display: inline-block;
      width: 48%;
      vertical-align: top;
      margin-#{$rdirection}: 10px;
    }
    .tier-name {
      display: none;
    }
    &.next-tier {
      @include breakpoint($medium-up) {
        margin-#{$rdirection}: 0;
      }
      .loyalty__panel__points__info-link {
        .info-link {
          .loyalty-page__content & {
            display: block;
          }
        }
      }
    }
  }
  &__info-container {
    @include breakpoint($medium-up) {
      margin-top: 45px;
    }
    margin-top: 20px;
  }
  &__info-header {
    @include breakpoint($medium-up) {
      width: 100%;
      margin-bottom: 30px;
    }
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 5px;
    display: inline-block;
    width: 50%;
    vertical-align: middle;
    .header-text {
      font-family: $body-font-bold;
    }
  }
  &__info-link {
    display: inline-block;
    width: 48%;
    font-size: 13px;
    line-height: 1;
    text-align: $rdirection;
    vertical-align: middle;
    @include breakpoint($medium-up) {
      width: 100%;
      text-align: $ldirection;
    }
    .info-link,
    .info-link--hyper-link {
      // LOYAL-6879 conflict, .info-link class is used in /themes/bb_base/template_api/editorial/email_signup_v1/build/email_signup_v1.js
      text-decoration: underline;
      color: $color-black;
      .loyalty-page__content & {
        display: none;
      }
      .tier-2 & {
        @if $lr2021 == true {
          color: $lr2021-tier-txt-color;
        } @else {
          color: $color-white;
        }
      }
      .tier-3 & {
        @if $lr2021 == true {
          color: $lr2021-tier-txt-color;
        } @else {
          color: $color-white;
        }
      }
    }
  }
  &__stats {
    @include breakpoint($medium-up) {
      margin-top: 20px;
    }
    .points__current {
      font-size: 80px;
      line-height: 1;
      font-family: $body-font;
      display: inline-block;
      padding-#{$rdirection}: 15px;
      @if $lr2021 == true {
        color: $lr2021-tier-txt-color;
      } @else {
        color: $color-fantastic;
      }
    }
    .points__text {
      font-family: $body-font;
      font-size: 32px;
      line-height: 1;
      display: inline-block;
      text-align: $ldirection;
      @if $lr2021 {
        display: block;
        text-align: center;
        @include breakpoint($medium-up) {
          text-align: $ldirection;
        }
      }
    }
  }
  &__next {
    text-transform: uppercase;
    &-points {
      font-family: $body-font-bold;
    }
  }
  &__rewards {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 10px;
    @include breakpoint($medium-up) {
      position: static;
      text-align: $rdirection;
      margin-#{$rdirection}: 5px;
    }
    &-link {
      color: $color-warm-grey;
      font-size: 15px;
      line-height: 1;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@if $lr2021 == true {
  .loyalty__panel__points__info-container {
    margin-top: 0;
  }
  .loyalty__panel__points__info-rewards-container {
    text-align: center;
    padding-bottom: 20px;
    display: block;
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
      padding-bottom: 0;
      text-align: $rdirection;
    }
  }
  body.device-mobile.account {
    .loyalty__panel__points__lr2021 {
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.account-page {
  .loyalty-loading {
    @include breakpoint($medium-up) {
      top: 50%;
      #{$ldirection}: 50%;
    }
    background-image: url('https://m.bobbibrowncosmetics.ca/media/export/cms/loading.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    #{$ldirection}: 45%;
    top: 14%;
    z-index: 999;
    height: 32px;
    width: 32px;
  }
  .account-page {
    &__header {
      font-size: 17px;
    }
    &__non__loyalty {
      margin-top: 15px;
      border-bottom: 1px solid $color-border-lighter-gray;
      padding-bottom: 85px;
      &__container {
        @include swap_direction(padding, 62px 0 0 40px);
        line-height: 1;
        display: inline-block;
        background-repeat: no-repeat;
        @if ($lr2021) {
          background-image: url('https://m.bobbibrowncosmetics.ca/media/export/cms/loyalty/account_loyalty_banner_pc_bg_lr2021.jpg');
          background-size: cover;
          height: 420px;
        } @else {
          background-image: url('https://m.bobbibrowncosmetics.ca/media/export/cms/loyalty/account_loyalty_banner_pc_bg.jpg');
          background-size: 100%;
          height: 380px;
        }
        &__title {
          &__content {
            width: 216px;
            visibility: visible;
          }
        }
      }
      &__title {
        font-size: 22px;
        letter-spacing: 2.5px;
        font-family: $body-font;
        text-align: center;
        line-height: 1.2;
      }
      &__loyalty__text {
        padding-top: 20px;
        width: 44%;
        color: $color-white;
        line-height: 1.4;
        font-size: 15px;
      }
      &__buttons {
        margin-top: 30px;
      }
      &__checkbox {
        margin-top: 20px;
        &__link {
          color: $color-white;
          text-decoration: underline;
        }
        .label {
          &:before {
            background-color: $color-white;
          }
          &-content {
            @include swap_direction(padding, 3px 0 0 4px);
            font-size: 13px;
            color: $color-white;
            display: inline-block;
            vertical-align: top;
            width: 47%;
            padding-top: 2px;
            line-height: 1.3;
            a {
              color: $color-white;
            }
          }
        }
      }
      &__join__now {
        background-color: $color-white;
        display: inline-block;
        border: 1px solid $color-white;
        letter-spacing: normal;
        text-align: center;
        &__link {
          padding: 2px 22px;
          line-height: 2.3;
          font-size: 14px;
          height: inherit;
          color: $color-black;
          font-family: $body-font-bold;
          background-color: $color-white;
          letter-spacing: 0;
          &:hover,
          &:focus {
            background: $color-white;
            color: $color-black;
          }
          &.button {
            &--disabled {
              background: $color-light-gray;
              &:hover,
              &:focus {
                background: $color-light-gray;
                color: $color-black;
              }
            }
          }
        }
      }
      &__learn__more {
        text-align: $ldirection;
        text-decoration: underline;
        font-size: 12px;
        display: inline-block;
        padding-#{$ldirection}: 30px;
        &__link {
          color: $color-white;
          letter-spacing: 1px;
        }
      }
    }
  }
  .loyalty__page {
    &__index {
      &__breadcrumb {
        padding-#{$ldirection}: 16px;
      }
    }
  }
}

.loyalty-page {
  &__content {
    .loyalty__panel__points__expiration,
    .loyalty__panel__points__buttons {
      display: block;
    }
  }
}
// Mobile Enrollment Tout
.account {
  .account__section {
    .account-mobile {
      &__non__loyalty {
        text-align: center;
        @if ($lr2021) {
          padding: 0 0 30px;
          background-color: $color-black;
          color: $color-white;
          a {
            color: $color-white;
          }
        } @else {
          padding: 10px 20px 30px;
        }
        &__image {
          margin-bottom: 20px;
        }
        &__header {
          margin-bottom: 20px;
          font-family: $bb-sans;
          font-size: 34px;
          line-height: 1;
          @if ($lr2021) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        &__sub-header {
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 1;
          span {
            font-family: $body-font-bold;
          }
          @if ($lr2021) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        @if ($lr2021) {
          &__disclaimer {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        &__join {
          &__checkbox {
            input[type='checkbox']:checked ~ .label {
              &:before {
                background-color: $color-black;
              }
            }
          }
          text-align: #{$ldirection};
          .label {
            &:before {
              margin-top: 5px;
              vertical-align: top;
            }
            &-content {
              display: inline-block;
              width: 88%;
              @if ($lr2021) {
                color: $color-white;
              } @else {
                color: $color-grey-footer;
              }
              a {
                @if ($lr2021) {
                  color: $color-white;
                } @else {
                  color: $color-grey-footer;
                }
              }
            }
          }
          &__button {
            text-align: center;
            margin-top: 30px;
          }
          &__link {
            width: 100%;
            padding: 10px;
            height: inherit;
            color: $color-black;
            background: $color-white;
            border: 1px solid $color-black;
          }
        }
        @if ($lr2021) {
          & > form {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
    }
  }
}

@if $lr2021 == true {
  .loyalty__panel__points__info-rewards-container {
    &-link {
      color: $color-black;
    }
  }
}
