@charset "UTF-8";

// Default Variables

$slick-font-path: '../../../fonts/' !default;
$slick-font-family: 'icons' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: #000 !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\e504' !default;
$slick-next-character: '\e505' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$opacity-default: 1;
$opacity-on-hover: 1;
$opacity-not-active: 0.25;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url, false);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}
/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
  &:focus {
    outline: none;
  }
  .slick-loading & {
    background: #fff url('https://m.bobbibrowncosmetics.ca/media/images/global/ajax-loader.gif') center center no-repeat;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  #{$ldirection}: 0;
  top: 0;
  display: block;
  zoom: 1;
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: $ldirection;
  height: 100%;
  min-height: 1px;
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }
  display: none;
  &.dragging img {
    pointer-events: none;
  }
  .slick-initialized & {
    display: block;
  }
  .slick-loading & {
    visibility: hidden;
  }
  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
/* Icons */
@if $slick-font-family == 'slick' {
  @font-face {
    font-family: 'slick';
    src: slick-font-url('slick/slick.eot');
    src: slick-font-url('slick/slick.eot?#iefix') format('embedded-opentype'),
      slick-font-url('slick/slick.woff') format('woff'), slick-font-url('slick/slick.ttf') format('truetype'),
      slick-font-url('slick/slick.svg#slick') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}
/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  display: block;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  @include swap_direction(padding, 0);
  border: none;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $opacity-not-active;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: $slick-font-family;
  font-size: 36px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  #{$ldirection}: 0;
  @include icon('arrow_left', 'before', false, false, 20px);
  position: absolute;
  &:before {
    color: $slick-arrow-color; // override a color attribute coming from the icon() mixin
    #{$rdirection}: auto;
    // content: $slick-prev-character;
  }
}

.slick-next {
  #{$rdirection}: 0;
  @include icon('arrow_right', 'before', false, fase, 20px);
  position: absolute;
  &:before {
    color: $slick-arrow-color; // override a color attribute coming from the icon() mixin
    // content: $slick-next-character;
  }
}
/* Dots */

.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  @include swap_direction(padding, 0);
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    @include swap_direction(margin, 0 5px);
    @include swap_direction(padding, 0);
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      @include swap_direction(padding, 5px);
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $opacity-default;
    }
  }
}
