@import 'checkout-panel-payment';
@import 'checkout-panel-review';
@import 'checkout-panel-button';
@import 'checkout-helpful-links'; //commented out and hidden
@import 'checkout-confirmation';
@import 'checkout-shared';
@import 'checkout-forms';

.order-details-page__content {
  .cart-item {
    &__replenishment {
      display: none;
    }
  }
  @include breakpoint($medium-down) {
    .cart-item__qty {
      width: 100%;
      &-label {
        display: inline-block;
      }
    }
  }
}

.shipping-panel {
  .email-and-sms-promotions {
    &.email-opted {
      display: none;
    }
  }
}

.customer-service.customer-service-landing {
  #landing-email {
    @include breakpoint($large-only) {
      background: none;
      border-top: none;
    }
  }
  #landing-faqs {
    @include breakpoint($large-only) {
      background: url('https://m.bobbibrowncosmetics.ca/media/images/account/bg_account_landing.gif') no-repeat 100% 0;
    }
  }
  #landing-contact {
    border-top: 1px solid $color-gray-alt;
  }
}

.cart-item {
  &__price {
    @include word-break(break-word) @include breakpoint($medium-up) {
      width: 19%;
    }
    &.complimentary_sample {
      width: 26%;
    }
    span.cart_percent--discounted {
      color: $color-pink;
      display: block;
      @include breakpoint($medium-down) {
        display: inline;
      }
      del {
        color: $color-black;
        text-decoration: line-through;
      }
    }
  }
  &__total {
    .checkout__content & {
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
  }
  &__qty {
    .checkout__content & {
      @include breakpoint($medium-up) {
        width: 26%;
      }
    }
  }
}

.cart-header {
  .qty {
    width: 26%;
  }
  .price {
    width: 18%;
  }
}

.device-mobile {
  .cart-item {
    &__thumb {
      &-image {
        width: auto;
      }
    }
  }
}

.sticky-checkout-overlay,
.sticky-checkout-button {
  display: none;
}

.bogo-offer {
  .cart-item {
    &__remove-form,
    &__replenishment,
    &__error--out-of-stock {
      display: none;
    }
    &__qty {
      padding-#{$ldirection}: 12px;
    }
  }
}
