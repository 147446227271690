$color-skin-beige: #fef6ec;
// mobile specific adjustments
$screen: 'only screen' !default;
$landscape: '#{$screen} and (orientation: landscape)' !default;
$portrait: '#{$screen} and (orientation: portrait)' !default;

.skin__foundation {
  background: $color-skin-beige;
  max-width: 1024px;
  position: relative;
  @include swap_direction(margin, 0 auto);
}

.skin__foundation__dropdown__container {
  @include swap_direction(padding, 14px 11.2% 18px 11.2%);
  border-bottom: 1px solid $color-border-gray;
  @include breakpoint($large-up) {
    @include swap_direction(padding, 44px 10% 48px 10%);
  }
  @include breakpoint($medium-down) {
    @include swap_direction(padding, 44px 5% 48px 5%);
  }
  @include breakpoint($small-down) {
    @include swap_direction(padding, 44px 3.3% 48px 3.3%);
  }
  // default-blank class make first select option appear to be blank
  .default-blank span.selectBox-label {
    display: inline-block;
    font-size: 0;
    width: 75%;
    height: 1px;
    //height: 60%;
    border-bottom: 1px solid $color-black;
    position: absolute;
    top: 60%;
  }
  // select menu mobile
  .skin__foundation__dropdown__select__container {
    //@include breakpoint($tablet-down) {
    //@include breakpoint($small-down) {
    @include breakpoint($medium-down) {
      height: 40px;
      position: relative;
    }
    @include breakpoint($medium-only) {
      height: inherit;
    }
  }
  .default-blank select.skin__foundation__dropdown__select {
    //@include breakpoint($tablet-down) {
    //@include breakpoint($small-down) {
    @include breakpoint($medium-down) {
      min-width: 60%;
      height: 23px;
      border-bottom: 1px solid $color-black;
      position: absolute;
      margin-#{$ldirection}: 5px;
    }
    @include breakpoint($medium-only) {
      height: 37px;
    }
  }
}

.skin__foundation__dropdown__header {
  font-family: $miller-banner-light;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -0.01em;
  @include breakpoint($medium-up) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 12px;
  }
}

.skin__foundation__dropdown__subheader {
  font-family: $brandon-text-medium;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  @include breakpoint($medium-up) {
    font-size: 20px;
    margin-bottom: 28px;
  }
  @include breakpoint($small-down) {
    font-size: 16px;
  }
}

.skin__foundation__dropdown__cta {
  @include swap_direction(margin, 0 5%);
  @include breakpoint($medium-up) {
    @include clearfix;
  }
}

.skin__foundation__dropdown__button {
  display: block;
  font-family: $brandon-text-bold;
  font-size: 10px;
  line-height: 28px;
  text-transform: uppercase;
  color: $color-black;
  text-decoration: none;
  text-align: center;
  border: 1px solid $color-black;
  width: 100%;
  margin-bottom: 4px;
  @include breakpoint($small-down) {
    font-size: 16px;
    height: 40px;
    padding-top: 5px;
  }
  @include breakpoint($medium-up) {
    float: $ldirection;
    //font-size: 20px;
    font-size: 18px;
    line-height: 56px;
    //width: 54%;
    width: 48%;
    @include swap_direction(margin, 0 4% 0 0);
  }
  &.inactive {
    @include opacity(0.5);
  }
}

.skin__foundation__dropdown__select__container {
  font-family: $brandon-text-bold;
  font-size: 10px;
  line-height: 28px;
  text-transform: uppercase;
  color: $color-black;
  text-decoration: none;
  border: 1px solid $color-black;
  width: 100%;
  margin-bottom: 4px;
  @include breakpoint($medium-up) {
    @include clearfix;
    float: $ldirection;
    //font-size: 20px;
    font-size: 18px;
    //height: 56px;
    height: 58px;
    line-height: 56px;
    //width: 42%;
    width: 48%;
  }
  &.inactive {
    @include opacity(0.5);
  }
  @include breakpoint($small-down) {
    font-size: 16px;
    padding-top: 5px;
  }
}

.skin__foundation__dropdown__select__copy {
  float: $ldirection;
  width: 35%;
  overflow: hidden;
  text-align: $rdirection;
  padding-#{$ldirection}: 8px;
}

.skin__foundation__result__link {
  margin-top: 15px;
  a {
    text-decoration: none;
    @include box-shadow($color-black 0 0 0 1px inset);
  }
}

a.selectBox.skin__foundation__dropdown__select {
  background: $color-skin-beige;
  color: $color-black;
  border: 0;
  //width: 100%;
  @include breakpoint($medium-up) {
    float: $ldirection;
    //font-size: 14px;
    font-size: 18px;
    height: 54px;
    //line-height: 54px;
    line-height: 54px;
    margin-top: 1px;
    width: 65%;
  }
  .selectBox-options a {
    font-size: 10px;
    line-height: 26px;
    height: 26px;
    text-transform: uppercase;
    background: $color-skin-beige;
    @include breakpoint($medium-up) {
      font-size: 20px;
      height: 54px;
      line-height: 54px;
    }
  }
  .selectBox-label {
    text-transform: uppercase;
    text-decoration: underline;
  }
  .selectBox-arrow {
    margin-#{$rdirection}: 8px;
    background: $color-skin-beige;
    line-height: 2;
    &:before {
      margin-top: 16px;
    }
  }
}

select.skin__foundation__dropdown__select {
  // width: 100%;
  text-transform: uppercase;
  border: none;
  background-color: $color-skin-beige;
  @include breakpoint($medium-down) {
    font-size: 18px;
  }
  //@include breakpoint($tablet-down) {
  @include breakpoint($small-down) {
    background-color: inherit;
    font-size: 16px;
    margin-top: -1px;
    min-width: 60%;
  }
  font-size: 10px;
  line-height: 28px;
  text-transform: uppercase;
  text-decoration: underline;
  margin-bottom: 0;
  @include swap_direction(padding, 0 5px);
  //@include breakpoint($medium-up) {
  @include breakpoint($large-up) {
    display: none;
    width: auto;
  }
  option {
    text-transform: uppercase;
  }
}

.js-skin__foundation__dropdown__select-selectBox-dropdown-menu {
}

.skin__foundation__finder__container {
  display: none;
  @include swap_direction(margin, 10px 24px);
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 40px 20px 0 20px);
  }
}

.skin__foundation__finder__header {
  font-family: $miller-banner-light;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -0.01em;
  margin-bottom: 10px;
  @include breakpoint($medium-up) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 30px;
  }
}

.skin__foundation__menus {
  @include breakpoint($medium-up) {
    @include clearfix;
  }
}

.skin__foundation__menu {
  text-align: center;
  @include swap_direction(padding, 12px 24px);
  margin-bottom: 4px;
  background: $color-white;
  cursor: pointer;
  &.inactive {
    @include box-shadow(none);
  }
  @include breakpoint($small-down) {
    background-image: none !important; //override inline
    &.active {
      @include box-shadow($color-black 0 0 0 2px inset);
    }
  }
  @include breakpoint($medium-up) {
    @include box-shadow($color-black 0 0 0 2px inset);
    position: relative;
    float: $rdirection;
    //background: url(https://m.bobbibrowncosmetics.ca/media/export/cms/skin_foundation/bg_menu01.jpg) no-repeat bottom $ldirection $color-white;
    background-repeat: no-repeat;
    background-position: bottom $ldirection;
    background-color: $color-white;
    background-size: contain;
    width: 32.333%;
    @include swap_direction(padding, 20px 18px 100px 18px);
    @include swap_direction(margin, 0 0 0 1.5%);
    &:last-child {
      @include swap_direction(margin, 0);
    }
    &.inactive {
      @include opacity(0.5);
    }
  }
}

.skin__foundation__menu__header {
  font-family: $brandon-text-bold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 20px;
    line-height: 30px;
  }
}

.skin__foundation__menu__description {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.06em;
  @include breakpoint($medium-up) {
    font-size: 20px;
    line-height: 30px;
    @include min-height(96px);
  }
  @include breakpoint($small-down) {
    padding-bottom: 10px;
    @include min-height(36px);
  }
}

.skin__foundation__menu__link__container {
  @include swap_direction(margin, 0 9%);
  width: 82%;
  @include breakpoint($medium-up) {
    position: absolute;
    bottom: 70px;
    #{$ldirection}: 0;
    @include swap_direction(margin, 0);
    width: 100%;
  }
}

.skin__foundation__menu__link {
  display: block;
  font-family: $brandon-text-bold;
  font-size: 10px;
  line-height: 26px;
  letter-spacing: 0.25em;
  color: $color-black;
  border: 1px solid $color-black;
  text-decoration: none;
  @include breakpoint($medium-up) {
    display: inline;
    font-size: 15px;
    line-height: 30px;
    text-transform: uppercase;
    text-decoration: underline;
    border: none;
    .skin__foundation__menu--02 & {
      span.icon-livechat {
        font-size: 25px;
        text-decoration: none;
      }
    }
  }
  @include breakpoint($small-down) {
    text-transform: uppercase;
  }
}

.skin__foundation__finishes {
  display: none;
  padding-bottom: 20px;
  @include breakpoint($medium-up) {
    @include clearfix;
    padding-bottom: 30px;
  }
}

.skin__foundation__finish {
  font-family: $brandon-text-bold;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-align: center;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-position: 0 -4px;
  background-color: $color-white;
  background-size: 100%;
  @include box-shadow($color-black 0 0 0 2px inset);
  width: 100%;
  height: 50px;
  @include swap_direction(margin, 20px 0 0 0);
  padding-top: 32px;
  @include breakpoint($medium-up) {
    background-position: bottom $ldirection;
    float: $ldirection;
    height: 65px;
    width: 32.333%;
    padding-top: 35px;
    @include swap_direction(margin, 30px 1.5% 0 0);
    cursor: pointer;
    &:last-child {
      @include swap_direction(margin, 30px 0 0 0);
    }
    &.inactive {
      @include box-shadow(none);
      @include opacity(0.5);
    }
  }
  @include breakpoint($small-down) {
    height: 60px;
    background-position: center 0;
    background-size: inherit;
    &.inactive {
      @include box-shadow(none);
      @include opacity(0.5);
    }
  }
}

.skin__foundation__models__container {
  display: none; // slide down
  @include breakpoint($medium-up) {
    height: 300px; //215px
    overflow-y: hidden;
    .slick-list {
      //margin-#{$ldirection}: -2px; // make room for last image
      margin-#{$ldirection}: -5px;
    }
  }
}

.skin__foundation__slide__container {
  @include transparent;
  @include transition(opacity, 0.5s ease-out);
  @include breakpoint($medium-up) {
    height: 300px; //215px
    overflow-y: hidden;
  }
  &.active {
    @include opacity(1);
  }
  .slick-prev,
  .slick-next {
    top: 33%;
    min-height: 50px;
    @media #{$portrait} {
      top: 25%;
    }
  }
  .slick-next {
    height: 50px;
    width: 50px;
    #{$rdirection}: 0;
    &:before {
      #{$rdirection}: 0;
    }
  }
}

.skin__foundation__model {
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 0 35px);
    //padding: 0 30px;
  }
  @include breakpoint($small-down) {
    &__shade {
      padding-top: 5px;
      padding-bottom: 10px;
    }
  }
}

.skin__foundation__model__id {
  float: $ldirection;
  width: 49%;
  padding-bottom: 10px;
  @include breakpoint($small-down) {
    padding-top: 1px;
    position: relative;
    &:nth-child(odd) {
      margin-#{$rdirection}: 2%;
    }
    //&.activated {
    //  @include box-shadow($color-black 0 0 0 2px inset);
    //}
    @media #{$landscape} {
      text-align: center;
      margin-#{$ldirection}: 11%;
      width: inherit;
    }
  }
  @include breakpoint($medium-up) {
    //float: none;
    position: relative;
    @include swap_direction(margin, 1px);
    width: 47%;
    min-height: 284px;
    @media #{$portrait} {
      min-height: 244px;
    }
  }
}

.skin__foundation__model__border {
  display: none;
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
  #{$rdirection}: 0;
  bottom: 0;
  @include box-shadow($color-black 0 0 0 2px inset);
  .selected & {
    display: block;
  }
  @include breakpoint($small-down) {
    .activated & {
      display: block;
    }
  }
}

.skin__foundation__model__image {
}

.skin__foundation__model__shade {
  font-family: $brandon-text-bold;
  letter-spacing: 0.25em;
  text-align: center;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 5px);
  }
}

.skin__foundation__model__shadnumber {
  font-size: 12px;
  line-height: 18px;
}

.skin__foundation__model__shadename {
  font-size: 18px;
  line-height: 22px;
  @include breakpoint($small-down) {
    font-size: 16px;
  }
}

.skin__foundation__result__container {
  background: $color-white;
}

.skin__foundation__shade__container {
  display: none;
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 30px 0);
    background: url(https://m.bobbibrowncosmetics.ca/media/export/cms/skin_foundation/bg_result_arrow.gif) no-repeat top center;
  }
}

.skin__foundation__shade {
  //display: none;
  //@include breakpoint($medium-up) {
  display: block;
  @include clearfix;
  @include transparent;
  @include transition(opacity, 0.5s ease-out);
  @include swap_direction(margin, 0 20px);
  &.active {
    @include opacity(1);
  }
  //}
}

.skin__foundation__smoosh__images {
  @include clearfix;
  float: $ldirection;
  width: 33%;
  padding-#{$ldirection}: 5%;
  @include breakpoint($small-down) {
    padding-top: 15px;
    width: 40%;
    @media #{$landscape} {
      padding-top: 15%;
      padding-#{$rdirection}: 15px;
    }
  }
}

.skin__foundation__smoosh__image {
  float: $ldirection;
  width: 50%;
  max-height: 250px;
}

.skin__foundation__result__shadename {
  float: $ldirection;
  width: 66%;
  height: 248px;
  @include swap_direction(padding, 70px 0 0 20px);
  @include breakpoint($small-down) {
    float: none;
    width: inherit;
    padding-top: 80px;
  }
}

.skin__foundation__result__finish {
  font-family: $brandon-text-bold;
  font-size: 20px;
  letter-spacing: 30px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.skin__foundation__result__description {
  font-size: 20px;
  letter-spacing: 30px;
  letter-spacing: 0.06em;
  @include breakpoint($small-down) {
    font-size: 13px;
  }
}

.skin__foundation__result__banner {
  position: relative;
  background: $color-black;
  color: $color-white;
  height: 40px;
  @include swap_direction(padding, 0 20px);
  text-align: center;
  @include breakpoint($medium-up) {
    height: 63px;
  }
}

.skin__foundation__result__banner__copy {
  font-family: $brandon-text-bold;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 40px;
    line-height: 63px;
  }
}

.skin__foundation__result__banner__precopy {
  font-size: 8px;
  @include breakpoint($medium-up) {
    font-size: 15px;
  }
}

.skin__foundation__result__banner__livechat {
  display: none;
  @include breakpoint($medium-up) {
    position: absolute;
    display: inline-block;
    top: 0;
    #{$rdirection}: 20px;
    font-family: $brandon-text-medium;
    color: $color-white;
    font-size: 18px;
    line-height: 63px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-decoration: none;
    width: 120px;
  }
}

.skin__foundation__result__banner__livechat {
  cursor: pointer;
}
