.customer-service-section {
  float: $rdirection;
  width: 70%;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
  @include breakpoint($small-down) {
    float: none;
    width: 100%;
  }
  h2,
  h3 {
    margin: 0;
    padding: 1.5em 20px;
    text-transform: uppercase;
    background-image: url('https://m.bobbibrowncosmetics.ca/media/images/service/customer-service-icon-arrow-closed.gif');
    @if $text-direction == 'rtl' {
      background-position: 2% center;
    } @else {
      background-position: 97% center;
    }
    background-repeat: no-repeat;
    border-top: 1px solid $color-gray-light-alt;
    cursor: pointer;
    font-size: 18px;
    font-family: $bb-sans;
    line-height: 1;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint($small-down) {
      font-family: $body-font;
      font-weight: bold;
      text-transform: none;
      border: none;
    }
  }
  h2.active-subsection,
  h2:hover,
  h3.active-subsection,
  h3:hover {
    color: $color-pink;
    background-image: url('https://m.bobbibrowncosmetics.ca/media/images/service/customer-service-icon-arrow-open.gif');
  }
  h2#contact-thanks,
  h2#contact-send,
  h2#contact-about,
  h3#contact-thanks,
  h3#contact-send,
  h3#contact-about {
    background: none;
  }
  div {
    display: none;
  }
  div.active-subsection {
    display: block;
    @include swap_direction(margin, 0 20px);
    @include swap_direction(padding, 0);
    border-top: 1px solid $color-gray-light-alt;
    @include breakpoint($small-down) {
      border-top: none;
    }
    p {
      @include swap_direction(margin, 1em 0);
      @include swap_direction(padding, 0);
      line-height: 20px;
    }
    ul {
      @include swap_direction(margin, 1em 20px);
      @include swap_direction(padding, 0);
      list-style-type: none;
    }
  }
}
